/* 1278px */
@media screen and (max-width: 1450px) {
    .header-toolbar .settings {
        width: 25%;
        float: left;
        color: var(--white);
    }
    .resp-line-height {
        line-height: 15px;
    }
    .header-toolbar .settings .group .switch-controls {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: relative;
        top: 1px;
    }

    .settings .group:nth-child(2) .switch-controls {
        top: -24px;
        left: -6px;
    }
    .header-toolbar {
        padding: 0 0 0 35px;
    }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 750px) {
    .header-switch div {
        display: none;
    }
}

/* Responsive Container CSS - will move to new file as we're getting way over 1000 lines here :) */

[data-section-anchor] {
    position: fixed;
    top: 0;
}
[data-demo-root] {
    margin-bottom: 1.5em;
}
[data-observe-resizes] { /* ! this is causing overlap issue with the ellipsis icon button on model sidebar */
    contain: content;
    overflow: hidden;
    position: relative;
}
/*.SM.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 6%;
}*/

/*.SM.sidebar #plane-offset-slider {
    width: 57%;
    margin: 17px 10px 0px 0px;
}
.MD.sidebar #plane-offset-slider {
    width: 150px;
    margin: 17px 10px 0px 10px;
}
.MD.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 8%;
}*/
.SM.sidebar #plane-offset-slider {
    /*width: 145px;*/
    width: 60%;
}
.SM.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 9%;
}
.SM.sidebar button.sy.accept-auto-keepouts {
    padding: 7px 5%;
}
.SM.sidebar button.sy.clear-auto-keepouts {
    padding: 7px 5%;
}
.SM.sidebar .module-specs-tool-actions {
    flex-direction: column;
    gap: 0.8rem;
}

.SM.sidebar button.sy.generate-auto-viewsheds {
    padding: 7px 9%;
}
.SM.sidebar .auto-viewsheds-container {
    flex-direction: column;
    margin: 20px 0;
    gap: 0;
}
.SM.sidebar .auto-viewsheds-density-controls,
.SM.sidebar .auto-viewsheds-strategy-selector.sw-multiway {
    margin-top: 12px;
}
.SM.sidebar .auto-viewsheds-density.visibility-hidden {
    display: none;
}
.SM.sidebar .viewsheds-auto .qmark-info {
    margin: 0 10px;
}
.SM.sidebar .viewshed-list-view .btn-horizon{
    margin-left: 77%;
}
.SM.sidebar .viewshed-list-view .btn-viewshed {
    margin-left: 65%;
}
.SM.sidebar .viewshed-list-view .shading-values-viewshed {
    width: 53%;
}
.SM.sidebar .viewshed-list-view {
    width: 83%;
}

.SM.sidebar .drone-img-thumbs .thumb {
    width: 45%;
}
/*.SM.sidebar .viewshed {
    width: 90px;
}*/
.MD.sidebar .viewshed-list-view .shading-values-viewshed {
    width: 70%;
}
.MD.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 33px;
}
.MD.sidebar button.sy.accept-auto-keepouts {
    padding: 7px 16px;
}
.MD.sidebar button.sy.clear-auto-keepouts {
    padding: 7px 16px;
}
.MD.sidebar .module-specs-tool-actions {
    flex-direction: row;
    gap: 0;
}

.MD.sidebar button.sy.generate-auto-viewsheds {
    padding: 7px 33px;
}
.MD.sidebar .auto-viewsheds-container {
    flex-direction: row;
    margin: 20px 0 0 0;
    gap: 10px;
}
.MD.sidebar .auto-viewsheds-density-controls,
.MD.sidebar .auto-viewsheds-strategy-selector.sw-multiway {
    margin-top: 18px;
}
.MD.sidebar .auto-viewsheds-density.visibility-hidden {
    display: block;
}
.MD.sidebar .viewsheds-auto .qmark-info {
    margin: 0 5px;
}
.MD.sidebar #plane-offset-slider {
    width: 200px;
}
.MD.sidebar .viewshed-list-view .btn-horizon {
    margin-left: 82%;
}
.MD.sidebar .viewshed-list-view .btn-viewshed {
    margin-left: 72%;
}
.MD.sidebar .viewshed-list-view {
    width: 84%;
}
.MD.sidebar .drone-img-thumbs .thumb {
    width: 30%;
}
/*.MD.sidebar .viewshed {
    width: 100px;
}*/
/*.MD.sidebar .viewshed-thumbnail .base {
    width: 110px;
    position: absolute;
    top: 13px;
    left: 20px;
    transition: .15s ease-in;
}
.MD.sidebar .viewshed-thumbnail .svg {
    margin: 0;
    width: 140px;
    position: absolute;
    top: 0;
    left: 5px;
    transition: .15s ease-in;
}*/

.LG.sidebar .viewshed-list-view .shading-values-viewshed {
    width: 82%;
}
.LG.sidebar .viewshed-list-view {
    width: 87%;
}
.LG.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 39px;
}
.LG.sidebar button.sy.accept-auto-keepouts {
    padding: 7px 25px;
}
.LG.sidebar button.sy.clear-auto-keepouts {
    padding: 7px 25px;
}
.LG.sidebar button.sy.generate-auto-viewsheds {
    padding: 7px 39px;
}
.LG.sidebar .auto-viewsheds-container {
    gap: 20px;
}
.LG.sidebar .viewsheds-auto .qmark-info {
    margin: 0 10px;
}
.LG.sidebar .viewshed-list-view .btn-horizon {
    margin-left: 85%;
}
.LG.sidebar .viewshed-list-view .btn-viewshed {
    margin-left: 76%;
}
.LG.sidebar #plane-offset-slider {
    width: 250px;
}
.LG.sidebar .drone-img-thumbs .thumb {
    width: 30%;
}
/*.LG.sidebar .fire-setback .keepout-edit-remove {
    margin-right: 60%;
}*/
/*.LG.sidebar #plane-offset-slider {
    width: 245px;
}
.LG.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 9%;
}
.LG.sidebar button.sy.accept-auto-keepouts {
    padding: 7px 1%;
    margin: 0px 4px 50px 0px;
    float: right;
}
.LG.sidebar button.sy.clear-auto-keepouts {
    padding: 7px 1%;
    margin: 0px 4px 50px 0px;
    float: right;
}
.XL.sidebar button.sy.generate-auto-keepouts {
    padding: 7px 8%;
}*/
/*.LG.sidebar #pp-info-tabs-info .monthly-usage {
    width: 100%;
}
.XL.sidebar #pp-info-tabs-info .monthly-usage {
    width: 40%;
}*/
.XL.sidebar .viewshed-list-view {
    width: 92%;
}
.XL.sidebar .drone-img-thumbs .thumb {
    width: 150px;
}
.XL.sidebar .drone-img-thumbs {
    justify-content: left;
    padding-left: 15px;
}

/* model ui responsivity */
.SM#split-main .header-switch {
    display: none;
}
.MD#split-main .header-switch {
    display: none;
}
.LG#split-main .header-switch {
    display: none;
}

.XL.sidebar .viewshed-list-view .btn-horizon {
    margin-left: 88%;
}
.XL.sidebar .viewshed-list-view .btn-viewshed {
    margin-left: 82%;
}
.XL#split-main .header-switch {
    display: flex;
}
/* please keep these commented out - helpful to use for guidance of where breakpoints are */
/*    [data-observe-resizes]::before {
    background-color: #ccc;
    color: hsla(0, 100%, 100%, 0);
    content: "\00a0";
    font-size: 0.8em;
    -webkit-font-smoothing: initial;
    font-weight: 700;
    letter-spacing: .1em;
    position: absolute;
    right: -3.1em;
    text-align: center;
    top: -1.2em;
    transform: rotate(45deg);
    transform-origin: 0 0;
    width: 6em;
    z-index: 10;
    }
    [data-observe-resizes][data-observing="true"]::before {
    transition: background-color 0.2s var(--material-easing) 0.05s,
    color 0.2s var(--material-easing) 0.05s;
    }

    [data-observe-resizes] [data-observe-resizes]::before {
    left: -1.2em;
    right: auto;
    top: 3.1em;
    transform: rotate(-45deg);
    }
    .SM[data-observe-resizes]::before {
    background-color: #40bf80;
    color: hsla(0, 100%, 100%, 100);
    content: "SM";
  }

  .MD[data-observe-resizes]::before {
    background-color: #33b2cc;
    content: "MD";
  }

  .LG[data-observe-resizes]::before {
    background-color: #bc79ec;
    content: "LG";
  }

  .XL[data-observe-resizes]::before {
    background-color: #f0427c;
    content: "XL";
  }
*/
