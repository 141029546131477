.gui .ui-accordion .ui-accordion-content.model-import-container {
    padding-bottom: 0;
}

.upload-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1%;
    align-items: center;
}

.model-import-options .buttons {
    display: flex;
    justify-content: space-between;
    height: 30px;
    flex-direction: row;
}
.model-import-options .buttons button {
    width: 80px;
    text-align: center;
}
.model-import-options .buttons button.delete-all {
    width: 50px;
}
.model-list li .actions .delete {
    padding: 4px 17px 4px 10px;
}

.tool-options .option.model-list-container {
    margin: 0;
}
.tool-options .model-import-options {
    overflow: inherit;
}

#scanifly-model-options {
    width: 142px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* select with custom icons */

.ui-selectmenu-menu {
    z-index: 3000;
}

.ui-selectmenu-open {
    z-index: 3000;
}
.ui-selectmenu-menu ul {
    width: 20vw;
    height: 12vh;
    overflow: auto;
}

.ui-selectmenu-menu .ui-menu.customicons .ui-menu-item .ui-icon {
    height: 12px;
    width: 12px;
    top: 0.1em;
}

.ui-menu-item {
    list-style: none;
    height: 5vh;
    cursor: pointer;
}

#low-poly {
    z-index: 3000;
}

.model-library-selection {
    display: flex;
    flex-direction: row;
    margin-left: 230px;
    background-color: var(--white);
}

#treeDropdown {
    width: 300px;
    overflow: scroll;
    display: contents;
}

/* Chrome, Safari, Edge, Opera */
/*input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}*/

/* Firefox */
/*input[type=number] {
  -moz-appearance:textfield;
}*/

#moduleDropdown .dd-select {
    width: 276px;
}
.dd-select a {
    /* With thumbnail */
    /* padding: 7px; */
    padding: 14px;
    /* maggie to add line arrow to this..
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('images/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: 97%;
    content: "";*/
}

.dd-desc {
    color: var(--dark-gray) !important;
    line-height: normal !important;
    font-size: 10px !important;
}
.select-vegetation .dd-selected-description {
    position: relative;
    right: 12px;
}
.dd-option, .dd-selected {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.dd-option {
    padding: 0px !important;
}

.dd-option-image, .dd-selected-image {
    max-width: 32px;
}

.row-title {
    display: flex;
    align-items: center;
    width: 118px;
}
.row-title input {
    margin-right: 4px;
}
#allModelsChecked {
    margin: 0 5px 0 0;
}

.model-list-container {
    max-height: 18vh;
    overflow-x: hidden;
    margin-top: 1.5vh;
    overflow-y: hidden;
}
.model-list-container > .row-title {
    margin-left: 10px;
    width: 100%;
}

.model-list li {
    display: flex;
    margin: 0;
}
.model-list li button .fa {
    margin: 0;
}
.model-list li .actions input {
    width: 50px;
    padding: 1%;
    margin: 0% 1%;
    margin-right: 4px;
}
.model-list li .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
}

.dd-selected-text {
    top: 30%;
    position: absolute;
}


@-o-keyframes fadeItTealToWhite {
    0%   { background-color: var(--white); }
    /* 50%  { background-color: #FFFF00; } */
    50%  { background-color: var(--teal); }
    100% { background-color: var(--white); }
  }
@keyframes fadeItTealToWhite {
0%   { background-color: var(--white); }
/* 50%  { background-color: #FFFF00; } */
50%  { background-color: var(--teal); }
100% { background-color: var(--white); }
}
@-o-keyframes fadeItTealToGray {
    0%   { background-color:  var(--very-light-gray); }
    /* 50%  { background-color: #FFFF00; } */
    50%  { background-color: var(--teal); }
    100% { background-color:  var(--very-light-gray); }
  }
@keyframes fadeItTealToGray {
0%   { background-color:  var(--very-light-gray); }
/* 50%  { background-color: #FFFF00; } */
50%  { background-color: var(--teal); }
100% { background-color:  var(--very-light-gray); }
}

.bkg-animated {
    background-image:none !important;
        -o-animation: fadeItTealToWhite .4s ease-in-out;
            animation: fadeItTealToWhite .4s ease-in-out;
}

.module-list-bkg-animated {
    background-image:none !important;
        -o-animation: fadeItTealToGray 1.1s ease-in-out;
            animation: fadeItTealToGray 1.1s ease-in-out;
}

.model-import-container .dd-select {
    top: 48px;
    left: 230px;
    width: 14vw;
    position: absolute;
    z-index: 1000;
}

/* .dd-options { */

.model-import-container .dd-options {
    height: 24vh;
    width: 14vw;
    overflow: auto;
    position: fixed;
    z-index: 1000;
    top: 391px;
    right: 391px;
}

.import-type-title {
    font-size: 10px;
}

.model-import-container .info {
    width: 102px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.model-import-container .info.error {
    overflow: visible;
}

.model-units {
    margin: 0 0 5px 2px;
}

.import-3d.option,
.select-vegetation.option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.model-type-selection button,
.model-library-selection button {
    height: 30px;
    text-align: center;
}

/* #moduleDropdown, */
#carportDropdown,
#electricalDropdown,
#shapesDropdown,
#lowPolyDropdown,
#highPolyDropdown,
#laddersDropdown,
#evDropdown,
#electricalDropdown,
#telecomDropdown,
#inverterDropdown {
    position: absolute;
    z-index: 1000;
    left: -230px;
}

.dropdown-common {
    width:110px;
    height: 30px;
    background-color: var(--white);
}

button.sy.delete-all {
    margin-left: 4px;
    background-color: var(--red);
}

.seed-options {
    padding: 2%;
    max-height: 15vh;
    overflow: scroll;
}

ui-icon.tree-oak {
    background: url("./images/tree2.png") 0 0 no-repeat;
}


ui-icon.tree-conifer {
    background-image: url("./images/tree2.png") 0 0 no-repeat;
}

.test-bkg {
    background-image: url("./images/tree2.png");
    height: 100%;
    width: 100%
}

.model-library-selection button.start-seeding {
    padding: 7px 14px;
    margin-right: 5px;
}
.model-library-selection button.plant-trees {
    padding: 7px 14px;
}
.model-library-selection button.stop-seeding {
    background-color:var(--green) !important;
    font-weight: bold !important;
    margin-right: 5px;
}

.grayed {
    background-color: gray;
}
