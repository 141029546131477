.main-header button.sy {
    padding: 0 10px;
}
.header-switch {
    display: flex;
    align-items: center;
    padding: 0px 15px;
}
.header-switch .toggle {
    margin: 0px 1px 0px 7px;
}
#header-module-lib {
    width: 50px;
    height: 50px;
}