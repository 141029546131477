.model-list {
    margin: -3px 0 0 0;
    max-height: 28vh;
    padding-inline-start: 0;
    overflow-x: auto;
    max-height: 16vh;
    /* overflow-y: hidden; */
}
.model-list li {
    list-style: none;
    padding: 5px;
    border: 1px solid #e3e3e3;
    margin: 5px 0;
    line-height: 18px;
    box-shadow: 2px 6px 32px -13px rgba(0,0,0,.25);
}
.model-list li .info {
    float: left;
}
.model-list li .actions {
    float: right;
}
.model-list li .actions button {
    margin-left: 5px;
}