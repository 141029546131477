button.sy {
    position: relative;
    overflow: hidden;
    background: var(--main-blue);
    border: none;
    padding: 7px 8px;
    color: var(--white);
    text-transform: none;
    font-weight: 700;
    cursor: pointer;
    border-radius: 8px;
}
.sidebar-deck button.sy {
    background: var(--main-blue);
    text-transform: none;
}
.sidebar-deck button.sy.btn-delete {
    background: var(--red);
}
button.sy:hover {
    opacity: .85;
    transition: all .25s ease-in-out;
}
button.sy:hover,
button.sy:focus {
    outline: 0;
}
button.sy:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(236, 240, 241, .3);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
button.sy:active:before {
    width: 120%;
    padding-top: 120%;
    transition: width .2s ease-out, padding-top .2s ease-out;
}
button.sy:disabled {
    background-color: var(--gray);
    cursor: not-allowed;
}
button.sy.captioned {
    text-align: center;
    width: 44px;
    height: 44px;
}
button.sy.captioned .caption {
    font-size: 8px;
}
button.sy.btn-tree.add-segment {
    padding: 7px 0px;
    width: 150px;
    text-align: center;
    background-color: var(--dark-gray)
}
button.sy.btn-tree.add-segment:hover {
    opacity: .85;
    transition: all .25s ease-in-out;
}
button.sy.btn-tree.add-layer {
    padding: 7px 2.5%;
}
button.sy.btn-tree.add-layer img {
    width: 12px;
    margin-top: -3px;
    vertical-align: middle;
}
button.sy.btn-tree {
    margin: 10px 0px 8px 10px;
}
.sy.btn-tree i {
    margin-right: 3px;
}
button.sy.gray {
    background-color: var(--dark-gray);
}
button.sy.manual-array-outline {
    text-align: center;
    width: 100%;
    padding: 10px;
    padding: 8px 10px;
}
button.sy.icon.active > img {
    filter: brightness(100);
}
button.sy.icon {
    border: 1px solid var(--gray);
    background: var(--white) !important;
}
button.sy.icon.active {
    background: var(--main-blue) !important;
    border: 1px solid var(--main-blue);
}
button.sy.icon-btn i {
    margin-right: 3px;
}
.input-wrapper > button.sy {
    padding: 6px 20px;
    font-weight: 500;
    border-radius: 8px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
button.sy.cancel, button.sy.edit {
    border: 1px solid var(--light-gray);
    background-color: var(--white);
    color: var(--dark-gray);
}
button.sy.save {
    padding: 8px 25px;
}
.actions button.sy.btn-tree {
    float: right;
    margin: 4px 10px 0 0;
}
.tool-options button .fa,
.load-changes button .fa,
.open-library button .fa,
.save-changes button .fa,
.add-roof-plane button .fa {
    margin-right: 3px;
}

button.sy.sidebar {
    font-size: 23px;
    box-shadow: none;
    background: var(--blue-gray);
    color: var(--dark-gray);
}
button.sy.sidebar .caption {
    display: none;
}
button.sy.header {
    font-size: 30px;
    box-shadow: none;
    background: var(--white);
    color: var(--dark-gray);
}
button.sy.header .caption {
    display: none;
}
.add-roof-plane button,
.standalone-button button {
    padding: 5px;
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
}
.add-roof-plane button {
    width: 345px;
    background: var(--green);
    font-weight: bold;
    height: 40px;
}
.standalone-button button {
    width: 345px;
}
.two .standalone-button button {
    width: 172.5px;
    float: left;
}
.two .standalone-button:first-child button {
    margin-right: 5px;
}

.btn-tree {
    margin: 10px;
}
.navbar button {
    padding-top: 5px;
}
.navbar button.active {
    border-radius: 8px;
    background-color: #D5DDE0;
}

.sy.manual-array-fill,
.sy.add-viewshed,
.sy.annotate {
    width: 100%;
    text-align: center !important;
    border-radius: 8px;
    background: var(--main-blue);
    font-weight: bold;
    padding: 9px;
}
.sy.add-viewshed {
    margin: 0px 0px 10px 0px;
}
#banner-img-screenshot {
    padding: 8px 20px;
}
button.sy.icon {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 6px;
    /*color: var(--dark-gray);
    border: 1px solid var(--blue-gray);*/
    color: var(--white);
    background-color: var(--main-blue);
    text-align: center;
}
button.sy.red {
    background-color: var(--red);
}
button.sy.icon.remove {
    color: var(--white);
    background-color: var(--red);
    border: none;
}
.sw-multiway {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    width: 100%;
    border-radius: 12px;
    border: none;
    color: var(--dark-gray);
    background: var(--white);
    line-height: 1.5;
    cursor: pointer;
}
.sw-multiway .sw-option:first-child {
    border-radius: 8px 0px 0px 8px;
}
.sw-multiway .sw-option:nth-child(n+2):nth-last-child(n+2) {
    border-radius: 0px;
}
.sw-multiway .sw-option:last-child {
    border-radius: 0px 8px 8px 0px;
}
.sw-multiway .sw-option {
    flex: 1;
    padding: 7px 10px;
    text-align: center;
    border: 1px solid var(--light-gray);
    float: left;
}
.sw-multiway:not(.disabled) .sw-option:hover {
    cursor: pointer
}
.sw-multiway:not(.disabled) .sw-option.active {
    background: var(--main-blue);
    border: .25px solid var(--main-blue);
    color: var(--white);
    font-weight: 800;
    line-height: 1.5;
    opacity: 1;
}
.sw-multiway .sw-option i {
    font-size: 10px;
    padding-right: 3px;
}
.sw-multiway .sw-option .disabled,
.sw-multiway.disabled .sw-option {
    background: var(--light-gray);
    color: var(--dark-gray);
    border: .25px solid var(--light-gray);
}
.sw-multiway .disabled,
.sw-multiway.disabled {
    background-color: var(--gray);
    color: var(--white);
    cursor: not-allowed !important
}
.sw-multiway .disabled:active,
.sw-multiway.disabled:active {
    pointer-events: none;
}
/*
styling for multiway selector
.sw-multiway {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    width: 100%;
    border-radius: 12px;
    border: none;
    color: var(--dark-gray);
    background: var(--white);
    line-height: 1.5;
    cursor: pointer;
}
.sw-multiway .sw-option:first-child {
    border-radius: 8px 0px 0px 8px;
}
.sw-multiway .sw-option:nth-child(n+2):nth-last-child(n+2) {
    border-radius: 0px;
}
.sw-multiway .sw-option:last-child {
    border-radius: 0px 8px 8px 0px;
}
.sw-multiway .sw-option {
    flex: 1;
    padding: 7px 10px;
    text-align: center;
    border: 1px solid var(--light-gray);
    float: left;
}
.sw-multiway .sw-option:hover {
    cursor: pointer
}
.sw-multiway .sw-option.active {
    background: var(--main-blue);
    border: .25px solid var(--main-blue);
    color: var(--white);
    font-weight: 800;
    line-height: 1.5;
    opacity: 1;
}
.sw-multiway .sw-option i {
    font-size: 10px;
    padding-right: 3px;
}
.sw-multiway .sw-option .disabled {
    background: var(--light-gray);
    color: var(--dark-gray);
    border: .25px solid var(--light-gray);
}
.sw-multiway .disabled {
    background-color: var(--gray);
    color: var(--white); cursor: not-allowed !important
}
.sw-multiway .disabled:active {
    pointer-events: none;
}
/*
styling for multiway selector
.sw-multiway {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid var(--light-gray);
    border: none;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    margin: 10px 0;
    height: 28px;
    line-height: 1.5;
    cursor: pointer;
    width: 100%;
}
.sw-multiway .sw-option {
    opacity: .55;
    padding: 5px 7px;
    flex: 1;
    text-align: center;
}
.sw-multiway .sw-option:hover {
    cursor: pointer
}
.sw-multiway .sw-option.active {
    background: var(--white);
    box-shadow: 0 .5px .5px var(--black-transp);
    border: .25px solid var(--light-gray);
    border-radius: 12px;
    color: var(--main-blue);
    font-weight: 800;
    margin: -1px;
    height: 17px;
    line-height: 1.5;
    opacity: 1;
}
.sw-multiway .sw-option i {
    font-size: 10px;
    padding-right: 3px;
}*/
.system-tree .sy.btn-delete {
    background: var(--red);
}

button.sy.icon.btn-horizon {
    background: url("./images/icons/obstruction-elevation-icon.svg") 49% 49% no-repeat;
    background-size: 55%;
    border: 1px solid var(--gray);
}
button.sy.icon.viewshed-remove {
    background: url("./images/icons/trash-icon.svg") 52% 49% no-repeat;
    background-size: 75%;
    background-color: var(--red);
    border: none;
    display: block;
    float: left;
    margin-right: 4px;
}
button.sy#nav-drone-images {
    background-image: url("./images/icons/drone-images-icon.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 62%;
}
button.sy#nav-exports {
    background-image: url("./images/icons/exports.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 62%;
}
button.sy#header-open-sidebar {
    background: var(--blue-gray);
    text-align: center;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    margin-top: 2px;
}

button.sy.header.svg {
    width: 50px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 63%;
}
button.sy.header.active {
    background-color: var(--blue-gray);
}
button.sy#header-save {
    background-image: url("./images/icons/save-icon.svg?x=202104151114");
}
button.sy#header-select {
    background-image: url("./images/icons/selector-icon-01.svg?x=202104151114");
}
button.sy#header-screenshot {
    background-image: url("./images/icons/screenshot-icon.svg?x=202104142012");
}
button.sy#header-thumbnail {
    background-image: url("./images/icons/project-thumbnail-icon.svg?x=202104151120");
}
button.sy#header-module-lib {
    background-image: url("./images/icons/module-library-icon.svg?x=202104142012");
}
button.sy#header-dimensions {
    background-image: url("./images/icons/dimensions-icon.svg?x=202104142012");
}

button.sy.delete {
    background-color: var(--red);
}
button.sy.draw-auto-segments {
    padding: 7px 50px;
    margin: 5px 4px 20px 0px;
    float: left;
}
button.sy.apply-auto-segments, button.sy.cancel-auto-segments {
    padding: 7px 25px;
    margin: 5px 4px 20px 0px;
    float: left;
}
button.sy.cancel-auto-segments {
    background-color: var(--red);
}
button.sy:disabled {
    background-color: var(--gray);
}

#nav-logo {
    cursor: pointer;
    margin-bottom: 5px;
}

button.sy.nocolor {
    background-color: transparent;
    color: var(--dark-gray);
}

button.sy.narrow {
    padding: 2px 2px
}
