html, body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
body {
    font: 12px "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: var(--dark-gray);
}
:root {
    --dark-gray:       #2E3134;
    --blue-gray:       #E4EBEE;
    --main-blue:       #1C48F2;
    --dark-blue:       #0024fc;
    --light-blue:      #008AFC;
    --teal:            #0DCAD3;
    --green:           #27E2A4;
    --yellow:          #FCCA65;
    --orange:          #F98445;
    --red:             #FF4D4D;
    --light-purple:    #B342CE;
    --purple:          #6E30E5;
    --teal:            #0DCAD3;
    --label-gray:      #6A6f83;
    --gray:            #CFCFCF;
    --light-gray:      #DFDFDF;
    --very-light-gray: #F5F5F5;
    --white:           #FFF;
    --black:           #000;
    --black-transp:    #00000040;
}
input {
    border: 1px solid var(--light-gray);
    padding: 5px 8px;
    border-radius: 8px;
    color: var(--dark-gray);
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
input:focus, input:visited {
    color: var(--dark-gray);
}
input.number {
    text-align: right;
    padding-right: 5px;
}
input.number-md {
    width: 40px;
}
input:disabled {
    cursor: not-allowed;
}
input:disabled:not([type='checkbox']) {
    background: var(--light-gray);
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    padding: 5px 20px 5px 5px;
    background-color: var(--white);
    background-image: url('images/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: 97%;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
}
button {
    font: 12px "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
li {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
*:focus {
    outline: none !important;
}
code {
    background: var(--blue-gray);
    padding: 1px 4px;
}
h1 {
    font-size: 1.75rem;
    font-weight: 700;
    padding: 10px 0px 0px 15px;
}
h2 {
    margin-left: 15px;
    float: left;
    font-size: 1.22rem;
    margin-top: 5px;
}
h3 {
    font-size: 1.15rem;
    color: var(--dark-gray);
}
h4 {
    font-size: .9rem;
}
.hidden {
    display: none !important;
}
.visibility-hidden {
    visibility: hidden;
}
.clearfix {
    clear: both;
}
td.center {
    text-align: center;
}

input[type=checkbox] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;
    border: 0px;
    padding: 0px;
}

input[type=checkbox]:before {
	font-family: "Material Icons Round";
    content: "\e5ca";
    font-size: 9px;
    color: transparent !important;
    background: var(--white);
    display: block;
    width: 9px;
    height: 9px;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    padding: 2px;
    margin-top: -1px;
    margin-left: 3px;
}

input[type=checkbox]:checked:before {
    border: 1px solid var(--main-blue);
    background: var(--main-blue);
	color: var(--white) !important;
    -webkit-text-stroke: 1px var(--white);
}

input[type=checkbox]:indeterminate:before {
    border: 1px solid var(--main-blue);
    background: var(--main-blue);
    color: var(--white) !important;
    -webkit-text-stroke: 1px var(--white);
    content: '–';
    text-align: center;
    line-height: 5px;
}

input[type="radio"] {
    border: 1px var(--main-blue);
}

input[type="radio"]:checked:before {
    content: "";
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--main-blue);
}

/*input[type=radio] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;
    border: 0px;
    padding: 0px;
}

input[type=radio]:before {
	font-family: "FontAwesome";
    content: "\f111";
    color: transparent !important;
    background: var(--white);
    display: block;
    width: 9px;
    height: 9px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    margin-top: -1px;
    margin-right: 3px;
}

input[type=radio]:checked:before {
    border: 1px solid var(--main-blue);
    background: var(--main-blue);
}*/

.progress-meter .background {
    fill: var(--light-gray);
}
.progress-meter .foreground {
    /* fill: #005a8b; */
    fill: var(--main-blue);
}
.progress-meter text {
    font-size: 24px;
    font-weight: bold;
    /* fill: #005a8b; */
    fill: var(--main-blue);;
}
.loader {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.loader .caption {
    text-align: center;
    font-weight: bold;
    color: var(--main-blue);
    font-size: 20px;
}

#stats {
    position: absolute;
    top: 1px;
    left: 270px;
}

.project-header {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--white);
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    margin: 0;
    padding: 8px 5px;
    display: none;
    font-size: 16px;
    line-height: 1;
}
.project-header .close {
    position: absolute;
    top: 2px;
    right: 12px;
    border: none;
    background: none;
    color: var(--white);
    font-size: 26px;
    line-height: 1;
    cursor: pointer;
    display: none;
}

.project-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--white);
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 18px;
    height: 30px;
    display: none;
}
.project-footer .powered-by {
    position: absolute;
    font-size: 12px;
    bottom: 7px;
    left: 5px;
}
.project-footer .logo img {
    width: 76px;
    position: absolute;
    bottom: 7px;
    left: 81px;
}
.project-footer .fullscreen {
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: var(--white);
    cursor: pointer;
    background: none;
    font-size: 16px;
    border: 1px solid var(--white);
    border-radius: 2px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
}
.selectBox {
    border: 2px solid var(--yellow);
    background-color: rgba(75, 160, 255, 0.25);
    position: fixed;
}
.start-screen {
    width: 100%;
    height: 100%;
    background: #bfd1e5;
    display: none;
}
.start-screen .start {
    text-decoration: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
    width: 160px;
    height: 160px;
    display: block;
    z-index: 2000;
    background: url('./images/icons/iframe-play-button-no-contour.png') no-repeat;
    background-size: 150px;
}

.start-screen .start:hover {
    background-position: 2px 2px;
}

.header-toolbar {
    position: fixed;
    width: 100%;
    display: none;
}
.header-toolbar .userpic img {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.header-toolbar .inner {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    margin-bottom: 5px;
    padding: 0 10px 0 0;
    background: var(--main-blue);
}
.logo {
    width: 44px;
    height: 44px;
    margin: 10px 0 0 0;
}
.header-toolbar .userpic {
    margin: 4px 5px 0 5px;
    float: right;
}
/* .header-toolbar .btn-wrap { */
.header-toolbar .btn-wrap,
.edit-buttons .btn-wrap {
    float: right;
    width: 60px;
    height: 50px;
    text-align: center;
}
/* .header-toolbar .btn-wrap button { */
.header-toolbar .btn-wrap button,
.edit-buttons .btn-wrap button {
    width: 50px;
    height: 50px;
    padding: 0;
    text-align: center;
}
.header-toolbar .userpic {
    margin-left: 15px;
}
.header-toolbar .settings {
    width: 45%;
    float: left;
    color: var(--white);
    margin: 0 0 0 430px;
    top: 4px;
    position: relative;
}
.header-toolbar .switch-label {
    padding: 13px 0 13px 35px;
}

.header-toolbar .settings .group {
    height: 50px;
    padding: 0 0 0 10px;
    width: 250px;
    float: left;
}

/* .settings .group:nth-child(1) {
    width: 0px;
    margin: 0 20px 0 0;
} */

.header-toolbar .switch-input:checked + .switch-label:after {
    background-color: var(--green);
}
.actions-header {
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
}
.annotations-empty {
    text-align: center;
    padding: 20px;
}
.annotations .input-wrapper {
    max-width: 190px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 2px;
}
.annotation,
.annotation-form {
    padding: 5px 0;
}
.annotation-form .actions {
    display: flex;
    flex-direction: row;
}
.annotation-form .option {
    margin-bottom: 3px;
    margin-left: 0px;
}
.annotation-form input[type=text],
.annotation-form textarea {
    padding: 4px;
    border: 1px solid var(--light-gray);
    border-radius: 4px;
}
.annotation-form .input-subject {
    width: 185px;
}
.annotation-form .input-subject {
    width: 185px;
}
/* CY */
.annotation-form .input-description {
    width: 185px;
}

.veg-annotation-form .input-height,
.veg-annotation-form .input-width {
    width: 40px;
}

.annotation .header {
    padding: 5px;
    background: #eee;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    cursor: pointer;
}
.annotation-marker, .veg-annotation-marker, .viewshed-annotation-marker {
    height: 15px;
    width: 15px;
    background: #0c69c4;
    color: var(--white);
    padding: 5px;
    border: 1px solid #003eff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 1em;
    cursor: pointer;
}
.veg-annotation-marker {
    background: green;
    border: 1px solid darkolivegreen;
}

#annotation-marker-popup, #viewshed-annotation-marker-popup {
    background: #0c69c4;
    color: var(--white);
    padding: 5px;
    border: 1px solid #003eff;
    border-radius: 3px;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 75px;
    max-width: 300px;
    max-height: 200px;
    overflow: auto;
}
#annotation-marker-popup .subject {
    font-weight: bold;
}
.ruler-display {
    background: var(--main-blue);
    color: var(--white);
    padding: 5px;
    border: 1px solid var(--main-blue);
    border-radius: 3px;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}
.gui {
    display: none;
    font-size: 10.5px;
    color: var(--very-light-gray);
    z-index: 1000;
    border-radius: 5px;
}
.gui .vtab-hide {
    width: 22px;
    height: auto;
    padding: 8px 5px;
    font-size: 14px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background: var(--main-blue);
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;
}
.gui .vtab-hide:hover {
    opacity: 1.0;
}
.gui.rhs {
    position: fixed;
    top: 73px;
    right: 5px;
    width: 424px;
}
.gui.rhs .inner {
    width: 382px;
    float: right;
    margin: 0 0 0 5px;
}
.gui.rhs .ui-widget {
    font-size: 11.5px;
}
.gui.rhs .vtab-hide {
    float: right;
    margin: 0 0 0 5px;
    border-radius: 50%;
}
.ui-selectmenu-button, .ui-selectmenu-button:hover, .ui-selectmenu-button:focus {
    margin: 0;
    padding: 3.5px 8px !important;
    background-color: var(--white);
    color: var(--dark-gray);
    border: 1px solid var(--light-gray) !important;
    border-radius: 8px !important;
    max-width: 130px !important;
    width: 100px !important; /* having to use importants to override jquery ui's styling or a lot of wonky things happen with these selectmenus */
}
.ui-widget.ui-menu.ui-widget-content {
    height: 100%;
    width: 100%;
    margin-top: -1px;
    padding-top: -2px;
}
.ui-selectmenu-menu .ui-menu {
    border-radius: 8px;
    box-shadow: 0px 4px 20px var(--black-transp);
    border: 1px solid var(--light-gray);
    padding-bottom: 0px;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background-color: var(--very-light-gray);
    border: var(--very-light-gray);
}
.ui-selectmenu-menu .ui-menu .ui-menu-item-wrapper.ui-state-active {
    height: 2.5em;
    line-height: 2.5em;
}
.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    margin: 0;
    border: none;
  }
.ui-selectmenu-category {
    padding: 1.5em 0.5em;
  }
.ui-selectmenu-menu .ui-menu .ui-menu-item-wrapper {
    height: 3em;
    line-height: 2.5em;
}
.ui-selectmenu-menu .ui-menu-item  {
    height: 3em;
    line-height: 2.5em;
}
.ui-selectmenu-menu .ui-menu-item:hover  {
    background-color: var(--very-light-gray);
}
.ui-selectmenu-menu .ui-menu.ui-widget-content .ui-corner-top  {
    border-radius: 0px;
    border: 0px;
}
#ui-accordion.accordion > .ui-corner-all, .ui-corner-bottom, .ui-corner-top {
    border-radius: 5px;
}

.gui .ui-corner-all,
.gui .ui-corner-bottom,
.gui .ui-corner-right,
.gui .ui-corner-br,
.gui .ui-corner-left,
.gui .ui-corner-bl,
.gui .ui-corner-top,
.gui .ui-corner-tr,
.gui .ui-corner-tl {
    border-radius: 0;
    border: none;
}
.gui .slider.ui-corner-all {
    background: var(--gray);
    border-radius: 10px / 6px;
}
.ui-slider.ui-corner-all {
    border-radius: 10px / 6px;
    border: var(--main-blue);
}
.ui-slider {
    width: 90px;
    margin: 5px 0 0 7px;
    float: left;
    margin-top: 2.05em;
}
.ui-slider .ui-slider-range {
    background: var(--main-blue);
    border: var(--main-blue);
    overflow: hidden;
    border-radius: 10px / 6px;
}
.ui-slider-horizontal {
    height: 3px;
    background: var(--gray);
    border: var(--gray);
    border-radius: 10px / 6px;
}
.ui-slider-horizontal .ui-slider-handle {
    top: -.45em;
    margin-left: -.6em;
    border: none;
    border-radius: 50%;
    background: var(--main-blue);
}
.ui-slider-horizontal .ui-slider-handle:active {
    background: var(--main-blue) !important; /* maggie to remove important if she changes active color */
}
.ui-spinner {
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    color: var(--dark-gray);
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-spinner .ui-spinner-button {
    background-color: var(--white);
}
.ui-tabs {
    padding: 0;
    margin-left: 12px;
}
.ui-tabs-nav {
    position: relative;
    display: block;
    margin: 0 -12px;
    padding: 7px 7px;
    border: 1px var(--light-gray) !important;
    background: var(--white);
}
.ui-tabs .ui-tabs-nav li {
    margin: 0 auto;
    background: var(--white) !important;
    border: 1px solid var(--light-gray);
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active,
.ui-tabs .ui-tabs-nav li.ui-state-active {
    border: 2px var(--main-blue);
}
.ui-tabs .ui-tabs-nav li a {
    color: var(--dark-gray);
}
.ui-tabs-nav li:first-child {
    border-radius: 8px 0px 0px 8px;
}
.ui-tabs-nav li:nth-child(n+2):nth-last-child(n+2) {
    border-radius: 0px;
}
.ui-tabs-nav li:last-child {
    border-radius: 0px 8px 8px 0px;
}
.ui-tabs-nav li:hover {
    background: var(--very-light-gray);
}
.close-slider:hover {
    cursor: pointer;
}
.close-slider {
    padding: 10%;
    display: inline;
    color: var(--dark-gray);
    margin: 0;
    background: var(--red);
}
div[class^="slide-container-"] .fa-times-circle {
    float: left;
}
.disabled-exports, .disabled-switch {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    background: var(--gray) !important;
    box-shadow: none !important;
    transition: none !important;
}
.disabled-switch { background: none !important; }

#segment-actions span,
.segment-tilt-az span {
    display: none;
}
#segment-actions {
    margin: 4px 0 0 0;
    float: none;
    line-height: none;
    text-align: left
}

.disabled-exports:hover {
    cursor: default !important;
}
.disabled-switch:hover > a {
    visibility: visible !important;
}
.disabled-exports:hover + a {
    visibility: visible !important;
}

.tool-options .option a,
.array-tab .details-container .details-visibility a,
div[class^="slide-container-"] .option .input-wrapper a,
/* // */
.array-tab .details-container .details-visibility a,
.fill-edit-container .edit-buttons a,
.help-wrapper a,
.help-wrapper .helper-text ,
.orientation-sub-container .helper-text,
.helper-text,
.edit-buttons a {
    position: absolute;
    visibility: hidden;
    left: 160px;
    height: auto;
    z-index: 100;
    width: 180px;
    color: var(--white) !important;
    background: var(--gray);
    font-size: 12px;
    margin: 8px;
    text-align:  center;
}
.fill-edit-container .edit-buttons a {
    left: 120px;
    bottom: 20px;
    font-weight: normal;
    padding: 4px;
    /* Is this consistent with other helper-texts? */
}
.orientation-sub-container .helper-text {
    padding: 4px;
    left: 80px;
    font-weight: normal;
}

.array-tab .details-container .details-visibility a {
    left: 0px;
    padding: 4px;
    top: -20px;
    text-align: center;
    width: 140px;
}

.active-btn {
    background: var(--yellow) !important;
}

.tool-options .option .input-wrapper a:hover,
.array-tab .details-container .details-visibility a:hover,
.fill-edit-container .edit-buttons a:hover,
.edit-buttons a:hover {
    visibility: visible !important;
}

.model-download + .tool-options button, div[class^="slide-container-"] button {
    width: 140px;
}

.slide-container-4 .option.download-nrel-report {
    margin-top: 25px;
}

.slide-container-3 .models3d button {
    margin-bottom: 1em;
}

div[class^="slide-container-"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    background: var(--white);
    position: absolute;
    left: -198px;
    top: 59%;
    overflow-y: auto;
    padding: .75% 0 4.5% 0;
    overflow: initial;
    color: var(--dark-gray);
    min-height: 12vh;
}
.tool-options .option {
    overflow-x: hidden;
    overflow-y: hidden;
}
.tool-options .input-wrapper {
    text-align: center;
    margin-left: 0;
    float: none;
}
.tool-options button {
    font-size: 10px !important;
}
.tool-options hr {
    border: 1px solid #e7e7e7;
    border-bottom: none;
}
.gui .ui-accordion .ui-accordion-content.tool-options {
    padding: 0 5px;
}
.tool-options .option {
    margin: 10px 5px;
}
.tool-options .option input[type="radio"] {
    margin-top: -2px;
    vertical-align: middle;
}

table.ui-form {
    width: 100%;
    border-collapse: collapse;

}
table.ui-form table {
    border-collapse: collapse;
    margin-right: 5px;
}
.gui.lhs table.ui-form td.label {
    float: none;
    width: 122px;
    line-height: 1.3;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.project-info.standalone-button {
    margin-bottom: 5px;
    display: none;
}
.project-info.standalone-button button {
    width: 345px;
}

.roof-plane-head {
    margin-top: 5px;
    height: 66px;
}
.roof-plane-head td {
    /* width: 50%; */
    vertical-align: top;
}
.remove-plane {
    text-align: center;
    padding-top: 15px;
}
.align-right {
    text-align: right;
}
div[class^="slide-container-"] .label {
    font-weight: bold;
}
div[class^="slide-container-"] hr { height: 2px; }
div[class^="slide-container-"] .divider {
    border-bottom: 1px solid var(--black);
    width: 87%;
    margin: 12px 0 6px 0;
}
div[class^="slide-container-"] .sub-label {
    /* font-size: 8px; */
    font-size: 10px;
    text-align: center;
    padding: 0px 0 8px 0px;
    /* font-weight: bold; */

}
div[class^="slide-container-"] .option {
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 2px;
}
.tool-options div[class^="slide-container-"] .option .label,
.tool-options div[class^="slide-container-"] .option .input-wrapper {
    text-align: center;
    width: auto;
    margin-left: 0;
    float: none;
}
.gui .label {
    float: left;
    width: 140px;
    line-height: 2em;
}
.gui .viewsheds-container .input-wrapper {
    margin-left: 0px !important;
}
.gui .input-wrapper {
    float: left;
    margin-left: 15px;
    line-height: 2em;
}
.gui .input-wrapper.first input[type=text] {
    width: 100px;
}
.gui .input-wrapper.second input[type=text] {
    width: 30px;
}
.gui .input-wrapper.third {
    margin-left: 60px;
}
.gui .input-wrapper select {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    min-width: 83px;
}
.gui .slider {
    width: 90px;
    margin: 10px 0 0 7px;
}
.gui .ui-accordion .ui-accordion-content {
    padding: 5px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-accordion-icons:hover .segment-name-edit {
    color: var(--label-gray);
}
.ui-accordion .ui-accordion-header {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 800;
}
.ui-accordion .ui-accordion-header.ui-state-default.ui-accordion-icons.ui-accordion-header-active {
    background-color:  var(--teal);
    color:  var(--dark-gray);
}
.ui-accordion .ui-accordion-header.ui-state-default.ui-accordion-icons.ui-accordion-header-active{
    border-radius: 0px !important;
}
.ui-accordion .ui-accordion-header.ui-state-default.ui-accordion-icons.ui-accordion-header-active:first-child {
    border-radius: 5px 5px 0px 0px !important;
}
.ui-accordion .ui-accordion-header.ui-state-default.ui-accordion-icons.ui-accordion-header-active:last-child {
    border-radius: 0px 0px 5px 5px !important;
}
.ui-accordion {
    border-radius: 10px !important;
}

.ui-icon {
    background-image: url(images/icons/ui-icons_777777_256x240.png) !important;
    margin-left: -4px;
}
.ui-state-active .ui-icon {
    background-image: url(images/icons/ui-icons_777777_256x240-white.png) !important;
}
.gui .input-shade-location {
    margin-bottom: 2px;
}
.gui .spacer {
    margin-top: 15px;
}

.input-rotate-degrees-value {
    text-align: right;
}
.input-rotate-degrees-lock {
    vertical-align: middle;
}

.controls {
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px var(--black-transp);
}
#pac-input {
    background-color: var(--white);
    margin-left: 12px;
    padding: 0 11px 0 6px;
    text-overflow: ellipsis;
    width: 300px;
    height: 34px;
}
#map-location {
    margin-top: 10px;
}
#map-location .grab {
    right: 10px !important;
}

.dxf-export-tool-view hr {
    border: none;
    width: 22px;
}
.dxf-export-tool-view.front hr {
    border-top: 2px dotted #ff0000;
}
.dxf-export-tool-view.back hr {
    border-top: 2px solid #ff0000;
}
.dxf-export-tool-view.left hr {
    border-top: 2px dotted #00ff00;
}
.dxf-export-tool-view.right hr {
    border-top: 2px solid #00ff00;
}
.dxf-export-tool-view.top hr {
    border-top: 2px solid #0000ff;
}

.gui.lhs .accordion {
    margin-bottom: 5px;
}
.gui.lhs .ui-accordion-content,
.gui.lhs .inner .ui-accordion-content,
/* .gui.lhs .inner .accordion .ui-accordion-content, */
.gui.lhs .accordion .ui-accordion-content {
    /* max-height: 52vh; */
    /* background: red; */
    max-height: 72vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.gui.lhs .accordion.empty {
    margin-bottom: 0;
}
.gui.lhs .accordion .header {
    border: none;
    border-radius: 0;
    margin-top: 0;
}
.gui.lhs .accordion .header.ui-state-active,
.gui.lhs .header.ui-state-active,
.gui.rhs .accordion .header.ui-state-active {
    background: var(--teal);
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    /*background: var(--main-blue) !important;*/
    color: var(---main-blue) !important;
}
.ui-widget-content {
    color: var(--dark-gray);
}
#dialog-project-info .ui-state-active {
    /*border: 2px solid var(--main-blue) !important;*/
    color: var(--dark-gray) !important;
}
.gui.lhs .add-roof-plane button {
    margin-top: 0;
}

.gui.rhs .accordion {
    background: var(--white);
    overflow: hidden;
}
.gui.rhs .accordion .header {
    border: none;
    border-radius: 0;
    margin-top: 0;
}
.gui.rhs .accordion .header:focus {
    outline: none;
}
.gui.rhs .accordion .model-import-container .tool-options {
    border: none;
    margin-top: 35px;
}

.gui.rhs .input-heat-map-threshold {
    width: 175px;
}

.gui.rhs .elevation-heat-map-threshold {
    height: 42px;
}
.gui.rhs .input-heat-map-threshold .ui-slider-handle.ui-state-active {
    color: var(--dark-gray);
}
.gui.rhs .input-heat-map-threshold .ui-slider-handle {
    content: " ";
    width: 10px;
    height: 10px;
    line-height: 45px;
    background: var(--main-blue);
    position: absolute;
    top: -3px;
    left: 3px;
    border-radius: 50%;
}
.gui.rhs .input-heat-map-threshold .ui-slider-handle.handle2 {
    background-color: var(--red) !important;
}

.plane-tabs {
    margin-top: 5px;
    margin-bottom: 5px;
}
.plane-tabs.ui-tabs .ui-tabs-panel {
    padding: 0 5px;
    overflow-x: hidden;
    max-height: 46vh;
    overflow-y: auto;
}

.align-top td {
    vertical-align: top;
}

.subsection {
    margin: 0 0 5px 0;
    border: 3px solid #eee;
}
.subsection h3 {
    margin: 5px 10px;
    font-weight: bold;
    color: var(--dark-gray);
}
.manual-array .subsection {
    border: none;
    font-size: 12.5px;
    margin-top: -1px;
}
.subsection .content div {
    display: flex;
    align-items: center;
}
.subsection .content {
    margin: 5px 0;
    padding: 0 10px;
}
.layers-accordion .subsection {
    margin-top: 5px;
}
.learn-more {
    color: var(--label-gray) !important;
}
input.number.small {
    width: 25px !important;
}
input.number.medium {
    width: 40px !important;
}
input.number.large {
    width: 60px !important;
}
.tags {
    display: flex;
    flex-wrap: wrap;
}
.tag {
    text-align: center;
    color: var(--white);
    border-radius: 6px;
    padding: 8px 10px;
    margin: 0px 5px 5px 0px;
    float: left;
    position: relative;
}
.tag:nth-child(1) {
    background-color: var(--orange);
}
.tag:nth-child(2) {
    background-color: var(--purple);
}
.tag:nth-child(3) {
    background-color: var(--teal);
}
.tag:nth-child(4) {
    background-color: var(--light-purple);
}
.tag:nth-child(5) {
    background-color: var(--light-blue);
}
.tag:nth-child(6) {
    background-color: var(--green);
}
.tag:nth-child(7) {
    background-color: var(--yellow);
}
.tag:nth-child(8) {
    background-color: var(--red);
}
.tag.red {
    background-color: var(--red);
}
.tag.yellow {
    background-color: var(--yellow);
}
.tag.green {
    background-color: var(--green);
}
