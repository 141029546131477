.modules-library button {
    margin: 2%;
}

.module-specs-wrapper .modules > li button.sy:disabled {
    color: var(--gray);
}

.module-specs-wrapper .modules > li > div.list-actions {
    /* right-align the terminal list-actions controls */
    margin-left: auto;
}

.module-specs-wrapper .modules > li .fa-star {
    color: var(--yellow);
}

#btn-open-library {
    padding: 15%;
    background: transparent;
    top: 3%;
}

.img-block.landscape img{
    transform: rotate(90deg);
}
.img-block.landscape img,
.img-block.portrait img {
    width: 30px;
    /* margin-top: 1px; */
}

.ui-dialog.module-library-dialog {
    height: 650px !important;
    z-index: 1300 !important;
    width: 900px !important;
    height: 700px !important;
}

.texture-map-container strong {
    padding: 2%;
}
.texture-map-container {
    position: absolute !important;
    top: 73.5% !important;
    z-index: 1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-25%);
}

#textureMapDropdown .dd-option {
    width: 100%;
}
#textureMapDropdown .dd-options {
    /* top: 0px !important; */
    /* height: 16vh !important; */
    position: absolute !important;
    top: -240px !important;
    left: 0px !important;
    z-index: 10000;
    height: 40vh !important;
}
#textureMapDropdown .dd-option-image,
#textureMapDropdown .dd-selected-image {
    /* max-width: 60px; */
    max-width: 56px;
    padding: 1.5% 2%;
}
#textureMapDropdown .dd-select {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 10000;
}
.module-selection-dropdown {
    display: none; /* Hide for now */
    border-radius: 0px;
    padding: 12px 4px;
    margin: 2%;
    width: 276px;
}

.module-selection-dropdown .option {
    text-align: left;
}
.module-library-dialog .ui-dialog-content {
    overflow: hidden !important;
}

.module-specs-wrapper .main-inputs .wrapper {
    display: table;
    width: 100%;
    margin: 4px 0;
}

.electrical-data {
    margin: 17px 0  17px 0;
}
.module-units {
    margin-top: 20px !important;
}

.main-inputs input {
    display: table-cell;
    width: 100%;
}
.main-inputs .container {
    display: table-cell;
    width: 1px;
    padding: 0 8px 0 0;
}

.module-specs-wrapper ul li:active,
.module-specs-wrapper ul li:hover {
    background: #FAFAFA;
}
.module-specs-wrapper ul
li {
    /* cursor: pointer; */
    padding: 1.5px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.module-specs-wrapper ul {
    list-style-type: none;
}
.module-specs-wrapper input {
    padding-left: 2px;
    padding: 1.25px 3px;
    margin-right: 10px !important;
}
.module-specs-wrapper{
    /* left: 50%;
    transform: translateX(-50%);
    top: 6vh; */
    gap: .2em;
    position: relative;
    height: auto;
    width: auto;
    display:grid;
    grid-template-columns: 3fr 3fr 2fr;
    grid-gap:1em;
    align-items: stretch;
    height:650px;
}

.module-specs-wrapper  p {
    line-height: normal;
    padding: 0;
    margin: 17px 0px;
    height: 10px;
}
.module-specs-wrapper > div {
    padding: 0em .25em;
}

#texture-maps {
    background: transparent;
    padding: 5px;
    font-size: 14px;
    border-color:grey;
    -webkit-appearance: none;
}

.module-specs-wrapper .col3 .module-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 140px;
}

.module-specs-wrapper .col2 table {
    margin-top: 5px;
    /* margin-top: 30px; */
}
.module-specs-wrapper .col2 .preview {
    width: 150px;
    /* width: 140px; */
}
.module-specs-wrapper .col2 input {
    width: 32px !important;
    margin-right: 4px;
}
.module-specs-wrapper .col1 input {
    margin-right: 10px;
}
.module-specs-wrapper .col2{
    display:flex;
    flex-direction:column;
    /* justify-content:space-between; */
    height: 90%;
}
.module-specs-wrapper .col3 .list-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.module-specs-wrapper .col3 ul {
    padding-inline-start: 0px !important;
}
.module-specs-wrapper .col3 {
    overflow: scroll;
    background-color: var(--very-light-gray);
    height: 98%;
}
.col3 button {
    margin: 1px;
}
.highlight-row {
    outline: 2px solid var(--teal);
    padding: 1%;
    margin: 2px 2px;
}
.library-actions {
    display: flex;
    position: relative;
}

.col1 {
    flex-direction: column;
    display: flex;
    margin-left: -5px;
}

.col1 .title {
    margin: 0 0 -6px 2px;
}

.module-specs-wrapper .col1 button {
    height: 40px;
    width: 120px;
    margin: 0 2px;
    text-align: center;
    font-weight: bold;
}
.module-specs-wrapper .save-button {
    padding: 9px 28px;
    background: var(--main-blue);
    border: 1px solid var(--main-blue);
}
.module-specs-wrapper .cancel-button {
    padding: 9px 18px;
    background: var(--white);
    border: 1px solid var(--light-gray);
    color: var(--dark-gray);
    margin-right: 5px;
}
.module-specs-wrapper .notes-class textarea {
    resize: none;
    text-align:left;
    width:100%;
    height: 62px;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
}

.moduleDropdown ul li .dd-option .dd-option-image {
    max-width: 36px;
    padding: 4px 0px 4px 2px;
}