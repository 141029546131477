.auto-segment-actions {
    width: 100%;
    position: relative;
    display: flex;
}
.auto-segment-actions-msg {
    width: 100%;
    position: relative;
    margin-top: -2px;
}
.auto-segment-actions-msg .alert-box {
    width: 92.5%;
    margin-left: -2.5%;
    font-size: 11px;
    margin-bottom: 20px;
    padding-top: 2px;
}
.auto-segment-actions-msg .alert-box span {
    line-height: 35px;
}
.auto-segment-header {
    width: 100%;
    position: relative;
    display: flex;
}
.auto-segment-header h3 {
    float: left;
}
.auto-segment-header .info {
    float: left;
    padding-top: 10px;
    margin-left: -8px;
}
.subsection .auto-seg-content {
    display: block !important;
}
.auto-segment-inputs {
    float: left;
    opacity: 50%;
    cursor: not-allowed;
}
.auto-segment-inputs input[type="radio"] {
    margin: 6px 10px 6px 7px;
    vertical-align: middle;
}
.x-ray-visibility {
    padding-top: 5px;
}
.auto-segment-inputs #auto-segment-setback {
    max-width: 17px;
    margin-left: 12px;
    margin-top: 4px;
}
.auto-segment-inputs .content {
    margin-top: -15px;
}
.auto-segment-inputs #setback-spinner {
    max-width:23px;
}
.auto-segment-inputs .units {
    padding-left: 7px;
}