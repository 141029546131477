.dialog-settings .buttons {
    justify-content: flex-end;
    width: 100%;
    padding-top: 15px;
}
.dialog-settings .buttons button {
    text-align: center;
    margin-left: 10px;
}
.dialog-settings .buttons button:first-child {
    margin-left: 0;
}
.dialog-settings .col-30 {
    width: 30%;
    float: left;
}
.dialog-settings .col-40 {
    width: 40%;
    float: left;
}
.dialog-settings .col-50 {
    width: 50%;
    float: left;
}
.dialog-settings .col-60 {
    width: 60%;
    float: left;
}
.dialog-settings .col-70 {
    width: 70%;
    float: left;
}
.dialog-settings #general-settings {
    padding-top: 1.35em;
}
.dialog-settings #performance-simulation {
    padding-top: 1.35em;
}
.dialog-settings #performance-simulation .dark-gray {
    color: var(--label-gray);
}
.dialog-settings #performance-simulation {
    padding-top: 1.35em;
}
.dialog-settings a {
    cursor: pointer;
}
.dialog-settings #performance-simulation button.sy.load {
    margin-left: 15px;
    margin-top: 3px;
    padding: 7px 15px;
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    color: var(--dark-gray);
}
.dialog-settings #performance-simulation .empty-fill {
    background-color: var(--very-light-gray);
}
.dialog-settings #performance-simulation td:nth-last-child(2) {
    border-radius: 0px 15px 15px 0px;
}
.dialog-settings #performance-simulation td:nth-child(3) {
    border-radius: 15px 0px 0px 15px;
}
.dialog-settings #performance-simulation #settings-map {
    width: 90%;
    height: 525px;
    border-radius: 12px;
    margin-top: 50px;
}
.dialog-settings #performance-simulation .load-settings-desc {
    text-align: right;
    float: left;
    margin-left: -5px;
    margin-right: 5px;
}
.dialog-settings #performance-simulation h4 {
    margin-bottom: 0px;
}
.dialog-settings #performance-simulation .qmark-info {
    float: left;
    margin-top: .65em;
    margin-left: 40%;
}
/*.dialog-settings #performance-simulation #settings-load-performance-simulation {
    width: 80px;
    float: left;
    font-size: 12px;
    margin-top: -22px;
    margin-left: 35px;
}*/
.dialog-settings #performance-simulation #settings-irradiance-model {
    width: 120px;
    margin: 10px 19px;
    float: left;
    margin-top: 1.45em;
}
.dialog-settings #performance-simulation #settings-load-performance-simulation {
    width: 80px;
    float: left;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 5px;
}
.dialog-settings #performance-simulation #settings-weather-data {
    width: 80px;
    margin: 8px 10px;
    float: left;
}
.dialog-settings #performance-simulation table {
    border-spacing: 0;
}
.dialog-settings #performance-simulation td input {
    width: 30px;
    margin-right: 4px;
}
.dialog-settings #performance-simulation td a {
    float: right;
    padding-right: 5px;
    color: var(--light-blue);
    font-size: 11px;
}
.dialog-settings #performance-simulation td:nth-child(1) {
    width: 200px;
}
.dialog-settings #performance-simulation table {
    border-spacing: 0px 4px;
}
.dialog-settings #three-d-scene {
    padding-top: 1.35em;
}
.dialog-settings #three-d-scene .color-setting {
    width: 100%; 
    display: flex; 
    padding-top: 14px;
}
.dialog-settings #three-d-scene .color-setting p {
    float: left; 
    margin-top: 0.5px; 
    margin-left: 12px;
}
.dialog-settings #three-d-scene .color {
    width: 16px; 
    height: 16px; 
    border-radius: 1.75px;
    float: left;
}
.dialog-settings #three-d-scene #color-setting-select {
    float: left; 
    width: 120px; 
    margin-top: 18px; 
    margin-left: 15px
}
.dialog-settings #three-d-scene .blue {
    background-color: var(--main-blue);
}
.dialog-settings #three-d-scene .green {
    background-color: var(--green);
}
.dialog-settings #three-d-scene .l-blue {
    background-color: var(--light-blue);
}
.dialog-settings #three-d-scene .orange {
    background-color: var(--orange);
}
.dialog-settings #three-d-scene .red {
    background-color: var(--red);
}
.dialog-settings #three-d-scene .yellow {
    background-color: var(--yellow);
}
.dialog-settings .ui-dialog-titlebar {
    font-size: 24px;
}
/*@media screen and (max-width: 1675px) {
    .dialog-settings .col-40 {
        width: 100%;
        float: none;
    }
    .dialog-settings .col-60 {
        width: 100%;
        float: none;
    }
    .dialog-settings #performance-simulation {
        padding-top: 0em;
    }
    .dialog-settings #performance-simulation .load-settings-desc {
        padding-left: .5%;
    }
}*/