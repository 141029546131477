.designs {
    font-weight: bold;
    margin: 8px 0 0 5px;
}
.section-layers button {
    width: 23px;
    height: 23px;
    margin-left: 5px;
}
/*.section-layers button.start-over {
    padding: 0 6px;
}*/
.versions {
    padding: 7px 10px;
    border-radius: 4px;
    margin: 0 1px 0 0;
}
.versions select {
    width: 200px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    padding: 5px 10px;
    margin-right: 5px;
    flex: 1 1;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.versions button.sy {
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    background: var(--white);
    color: var(--label-gray);
    text-align: center;
    box-shadow: none;
}
.versions .row {
    display: flex;
    justify-content: space-between;
}
.versions .row h2 {
    margin: -5px 0 15px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.versions .ellipsis-v {
    width: 33px;
    height: 33px;
}
.versions .version-select {
    width: 100px;
    margin-right: 5px;
    position: relative;
    z-index: 1;
    text-overflow: ellipsis;
    padding-right: 2.5rem;
}
.versions .ui-selectmenu-button, .versions .ui-selectmenu-button:hover, .versions .ui-selectmenu-button:focus {
    width: 87% !important;
    margin-right: 3px;
    padding-top: 6px !important;
    max-width: 950px !important;
}
.versions .version-dropbtn {
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.versions .ellipsis-v i {
    vertical-align: middle;
    font-size: 13px;
    padding-top: 2px;
}
.versions .dropdown-menu {
    display: none;
}
/*.ui-widget .version-select {
    width: 200px;
    border-width: 2px;
    background-position: 99%;
    font-size: 12px;
    margin-right: 10px;
}*/
.version-select:hover {
    cursor: pointer;
}
.extra-info .copy {
    display: none;
}

.versions .extra-info {
    background: var(--white);
    width: 50px;
    height: 50px;
    border: 1px solid var(--dark-gray);
    border-radius: 8px;
    margin: 5px 0 0 0;
    line-height: 50px;
}
.versions .extra-info::before {
    color: var(--dark-gray);
    font-size: 30px;
    content: "i";
}

.navbar a {
    cursor: pointer;
}

.dropdown-content, .dropdown-content-versions {
    margin-top: -0.5px;
    display: none;
    position: absolute;
    background-color: var(--white);
    min-width: 160px;
    border: 1px solid var(--light-gray);
    z-index: 9000;
    border-radius: 8px;
    margin-left: -127px;
}
.dropdown-content-versions {
    margin-left: 0px;
    margin-top: 32px;
    width: 77%;
    z-index: 1100;
}
.dropdown-content a {
    float: none;
    color: var(--dark-gray);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    user-select: none;
}
.dropdown-content > div:first-child a {
    border-radius: 5px 5px 0 0;
}
.dropdown-content > div:last-child a {
    border-radius: 0 0 5px 5px;
}
.dropdown-content a.disabled, .dropdown-content-versions option.disabled {
    color: var(--blue-gray);
    pointer-events: none;
}
.dropdown-content .caption, .dropdown-content-versions .caption {
    display: inline-block;
    margin-left: 5px;
}
.dropdown-content-versions option {
    float: none;
    color: var(--dark-gray);
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    user-select: none;
}
.dropdown-content-versions > div:first-child option {
    border-radius: 5px 5px 0 0;
}
.dropdown-content-versions > div:last-child option {
    border-radius: 0 0 5px 5px;
}
.dropdown-content-versions option:hover, .dropdown-content a:hover {
    background-color: var(--very-light-gray);
    cursor: pointer;
}

#dropdown {
    display: none;
}
#dropdown-versions {
    display: none;
}