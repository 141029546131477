.input-parapet,
.input-setback {
    width: 40px !important;
}

.module-specs-tool-keepouts {
    width: 100%;
}
.module-specs-tool-keepouts tr:first-child {
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    background-image: linear-gradient(to right, var(--dark-gray) 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}
.module-specs-tool-keepouts table tr {
    padding: 20px !important;
}
.module-specs-tool-keepouts td {
    vertical-align: middle;
    text-align: center;
    width: 27px;
    padding: 2px;
}
.module-specs-tool-keepouts td.direction {
    width: 47%;
}
.module-specs-tool-keepouts td.keepout-type {
    width: 10px;
}
.module-specs-tool-keepouts .icon, .module-specs-tool-viewsheds .icon {
    display: inline-block;
    text-align: center;
    vertical-align: middle
}
.fire-setback .icon {
    display: inline-block;
    width: 20px;
    margin-left: auto;
    text-align: center;
    vertical-align: middle;
}
.fire-setback .icon .fa {
    font-size: 1rem;
}
.fire-setback .title {
    font-size: 14px;
}
.setback-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
}

.module-specs-tool-keepouts td input {
    width: 100%;
    box-sizing: border-box;
    min-width: 40px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-align: left;
}
.module-specs-tool-keepouts .units {
    color: var(--label-gray);
}
.sidebar-keepouts h4 {
    margin-block-end: 0px;
    margin-top: 0.5rem;
}
.sidebar-keepouts {
    overflow-x: auto;
    width: 100%;
}
.sidebar-keepouts img {
    vertical-align: middle;
    margin-top: -0.1rem;
}

.sidebar-keepouts-row {
    display: flex;
    gap: 0.8rem;
}

.keepout-button {
    width: 2rem;
    height: 2rem;
}
#plane-tabs-keepouts-0 {
    margin-top: 0px;
}
.module-specs-tool-actions button .keepout-square {
    margin-left: 0px;
}

.keepout-fs {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
}

.fire-setback {
    height: auto;
    min-height: 2.5rem;
    margin-top: 1.2rem;
    font-size: 12px;
    white-space: nowrap;
}
button.keepout-fs {
    font-weight: 400;
    float: left;
    width: 50%;
    min-width: fit-content;
}
.sidebar-keepouts .units {
    background: var(--blue-gray);
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
}
#sidebar-keepouts-left, #sidebar-keepouts-right {
    width: 50%;
}
.sidebar-keepouts-row {
    width: 100%;
}
.fire-setback .switch-label {
    padding-right: 0px;
    padding-left: 0px;
    width: 34px;
}
.fsColorToggle {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}
.fsColorToggle .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fire-setback .keepout-edit-remove {
    display: flex;
    gap: 10px;
}
.fire-setback .inner {
    display: flex;
    padding: 5px;
    background: var(--blue-gray);
    border-radius: 4px;
    margin: 10px 0;
    justify-content: space-between;
}
.fire-setback-buttons {
    display: flex;
    gap: 0px;
}
.keepout-row:hover,
.module-specs-tool-keepouts ul li:hover {
    background: #0c69c436;
}
.module-specs-keepout-extrude {
    padding: 5px 10px 5px 2px;
    width: 100%;
}
.module-specs-keepout-extrude select {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    color: var(--dark-gray);
}

.module-specs-tool-keepouts image {
    width: 22px;
    height: 22px;
    text-align: center;
    vertical-align: middle;
    padding: 2px;
    margin: 0 2px;
}
.keepout-edit-remove button,
.module-specs-tool-keepouts button {
    width: 22px;
    height: 22px;
    text-align: center;
    vertical-align: middle;
    padding: 2px;
    margin: 0 2px;
}
.keepout-edit-remove button .fa,
.tool-options .module-specs-tool-keepouts button .fa {
    font-size: 12px;
    margin: 0;
}
.keepout-edit-remove button .fa-pencil-square-o,
.tool-options .module-specs-tool-keepouts button .fa-pencil-square-o {
    margin: 1px 0 0 3px;
}

#plane-offset-slider {
    width: 180px;
    margin: 17px 10px 0px 2px;
    border: var(--main-blue);
}
.keepouts-auto .label {
    margin-block-end: 0rem;
    margin-block-start: 0.5rem;
    margin-top: 2px;
}
.keepouts-auto > input {
    font-size: 11px;
    width: 10px;
    color: var(--dark-gray);
    margin: 8px 5px 0px 8px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    float: left;
}
.auto-keepouts-header {
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
}
.auto-keepouts-header h4 {
    float: left;
}
.dialog-gen-keepouts {
    text-align: center;
}
.dialog-gen-keepouts i {
    font-size: 3em;
    padding-top: 0px;
    padding-bottom: 5px;
    text-align: center;
}
.dialog-gen-keepouts h3 {
    font-size: 2em;
    line-height: 1em;
}
.dialog-gen-keepouts p {
    line-height: .3em;
    font-size: 1.2em;
}
.sidebar-keepouts-container {
    display: flex;
}
.keepout-buttons {
    display: flex;
    width: 100%;
}
.keepouts-auto > .ui-spinner {
    margin-top: 2px;
}
.keepouts-auto #spinner {
    width: 32px !important;
}
.keepouts-auto .ui-spinner-disabled, .keepouts-auto .ui-button-disabled {
    background-color: var(--light-gray);
}
.keepouts-auto .ui-slider-disabled > .ui-slider-handle {
    background-color: var(--dark-gray);
}
.keepouts-auto .ui-slider-disabled > .ui-slider-range {
    background-color: var(--dark-gray);
}
.keepouts-auto .ui-state-disabled {
    opacity: .5;
}
.keepout-auto .auto-keepout-inputs {
    width: 100%;
}
.keepouts-auto .ui-widget.ui-widget-content {
    margin-right: 5px;
}
button.sy.generate-auto-keepouts {
    padding: 7px 27px;
    margin: 5px 4px 20px 0px;
    float: left;
}
button.sy.accept-auto-keepouts, button.sy.clear-auto-keepouts, button.sy.delete-all-auto-keepouts {
    padding: 7px 15px;
    margin: 5px 4px 20px 0px;
    float: left;
}
button.sy.clear-auto-keepouts, button.sy.delete-all-auto-keepouts {
    background-color: var(--red);
}
button.sy.delete-all-auto-keepouts {
    padding: 7px 27px;
    margin: 5px 4px 30px 0px;
}
button.sy.clear-auto-keepouts:disabled {
    background-color: var(--gray);
}
.auto-keepouts .delete-all-section {
    width: 100%;
    display: flex;
}
.keepout-color-key {
    float: right;
}
.keepout-spacer {
    width: 100%;
    height: 60px;
    background-color: var(--white);;
}
.keepout-type {
    width: 5px;
    font-size: 10px;
    border-radius: 9px;
    color: var(--white);
    text-align: center;
    border: 3px solid var(--white);
    background-color: var(--red);
}
td.keepout-type.auto {
    background-color: var(--teal);
}
td.keepout-type.manual {
    background-color: var(--red);
}
/*
.keepout-type, .keepout-transition .auto {
    background-color: var(--teal);
}
.keepout-type, .keepout-transition .manual {
    background-color: var(--orange);
}
.keepout-transition{
  transition-delay: .3s;
  -webkit-transition-delay: .3s;
  transition: width .4s;
  -webkit-transition: width .4s;
  transition-timing-function: ease;
}
.keepout-transition:hover{
  width:75px;
}*/
.keepouts-auto > hr {
    width: 100%;
    margin: 10px 0 20px 0;
    opacity: .5
}
.automatic-label {
    color: var(--teal);
    font-size: 14px;
    padding: 2px 2px 0px 0px;
}
.manual-label {
    color: var(--red);
    font-size: 14px;
    padding: 2px 2px 0px 0px;
}
