.split .sidebar-title.hbox {
    flex: 0 0 66px;
    align-items: center;
    justify-content: space-between;
}
.sidebar-title button.sy {
    background: var(--white);
    color: var(--dark-gray);
    font-size: 33px;
    box-shadow: none;
    margin-right: 10px;
    font-weight: 600;
}
.sidebar h4.get-started {
    padding: 5% 15%;
}
.sidebar h3 {
    margin: 8px 0 13px 0;
    display: none; /* it was suggested we hide these for more vertical space – I think some could still use them (like occluder below and segements). Should be shown again when we have panel on the right */
}

.occluder-heading h3, 
.auto-segment-header h3, 
.manual-array h3,
.all-segs h3,
.seg-info h3 { /* keep until we show h3s again */
    display: block;
}


.sidebar .pane-container {
    padding: 0 10px;
}
.sidebar .pane-container .get-started {
    text-align: center;
    font-size: 14px;
    padding-top: 20%;
    padding-bottom: 12.5%;
}
.sidebar .pane-container .get-started-illustration {
    /*width: 80%;
    margin-left: 10% for undraw illustration*/
    width: 96%;
    margin-left: 2%;
    margin-top: 5%;
}

.segment-props {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.segment-props .azimuth,
.segment-props .tilt {
    width: 120px;
}
.segment-props .info {
    width: 50px;
    text-align: left;
}

.segment-props img {
    width: 19px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.block {
    background: var(--blue-gray);
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
}
.block-outline {
    border: 3px solid var(--blue-gray);
    border-radius: 4px;
    padding: 10px;
}
.block a {
    color: var(--scanifly-active-color);
}

.qmark-info {
    display: inline-block;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    cursor: pointer;
    background: var(--light-blue);
    font-size: 15px;
    line-height: 1.4;
    margin: 0 20px;
}
.qmark-info:before {
    content: "?";
    font-weight: bold;
    color: var(--white);
    box-align: middle;
}
.qmark-info .tooltip-content {
    display: none;
}
.shading-values-mini {
    display: flex;
    justify-content: space-around;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    padding: 0px 2.5px;
    margin-bottom: -5px;
    margin-top: 2px;
    cursor: default;
}
.shading-values {
    width: 100%;
}
.shading-values .number {
    text-align: right;
    font-weight: bold;
}
.shading-values td {
    padding: 0;
    margin: 0;
}
.shading-value {
    color: var(--label-gray);
}
.system-tree-totals {
    margin: 10px 0 0 0;
    color: var(--label-gray);
    width: 100%;
}
table.system-tree-totals tr {
    float: left;
}
table.system-tree-totals tr:nth-child(1) {
    margin-right: 20px;
}
.input-panel-tilt,
.input-panel-azimuth {
    width: 40px;
}

.segment-props input {
    padding: 2px 6px;
}

.card {
    padding: 13px;
    margin: 13px 0;
    border-radius: 8px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}
.card:first-child {
    margin-top: 0;
}
.card h4 {
    margin: 0 0 13px 0;
    font-size: 1rem;
}
.az-tilt-area {
    margin-bottom: 13px;
}
.az-tilt-area div {
    display: flex;
    justify-content: space-between;
}
.az-tilt-area span {
    color: var(--label-gray);
}

.empty .card {
    margin-top: 20px;
    text-align: center;
    padding: 40px;
    font-size: 14px;
    line-height: 23px;
}