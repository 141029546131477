.viewshed {
    position: relative;
    margin: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
    border-radius: 12px;
    display: flex;
    width: 90px;
    float: left;
    padding: 15px 25px 0px 25px;
}
.viewshed-list-view {
    position: relative;
    margin: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
    border-radius: 12px;
    display: flex;
    float: left;
    width: 84%;
    padding: 15px 25px 0px 25px;
}
.viewshed .viewshed-thumbnail:hover, .viewshed .horizon-graph:hover {
    cursor: zoom-in;
}
.viewshed .shading-values-viewshed  {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 2px;
    margin-top: -15px;
    margin-left: -15px;
}
.viewshed-list-view .shading-values-viewshed {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 2px;
    margin-top: -25px;
    margin-left: 45px;
}
.viewshed .tags .tag, .viewshed-list-view .tags .tag, .pp-info-viewsheds .tag {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    font-size: 10px;
    padding: 2px;
}
.dialog-viewshed .tag {
    padding: 5px 8px;
    font-size: 12px !important;
}
.dialog-viewshed .tags .viewshed-tag {
    width: 12.5px;
    line-height: 17.5px;
    border-radius: 100%;
    margin-right: 8px;
    margin-top: 2px;
}
.section-viewsheds .units {
    background: var(--blue-gray);
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
}
.shade-value {
    font-size: 10.5px;
    color: var(--label-gray);
    padding: 0px 2.5px;
}
.viewshed-color-key {
    float: right;
}
.viewshed .viewshed-content {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    text-align: center;
    position: relative;
}
.viewshed-list-view .viewshed-content {
    width: 100%;
    text-align: left;
    padding-top: 13px;
    padding-left: 15px;
    text-align: center;
    position: relative;
}
.viewshed-content .viewshed-marker {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    float: right;
    margin: 1px 5% 0px -5%;
}
.viewshed-content .viewshed-marker-other-option {
    width: 20px;
    line-height: 20px;
    font-size: 18px;
    border-radius: 50%;
    margin-left: 48px;
}
.viewshed .tags, .viewshed-list-view .tags {
    display: block;
    position: absolute;
    z-index: 10;
    margin-left: 12px;
    float: left;
}
.viewshed-type {
    font-size: 17px;
    margin-left: 2px;
}
.dialog-viewshed .tags {
    display: block;
    margin-left: 12px;
}
.dialog-viewshed h3 {
    margin-block-start: 4px;
}
.viewshed .tag, .viewshed-list-view.tag, .dialog-viewshed .tags {
    padding: 0.65px 5px;
}
.viewshed .tag.auto, .viewshed-list-view.tag.auto, .dialog-viewshed .tag.auto {
    background-color: var(--teal);
}
.viewshed .tag.manual, .viewshed-list-view.tag.manual, .dialog-viewshed .tag.manual {
    background-color: var(--red);
}
.viewshed .tag::first-letter, .viewshed-list-view.tag::first-letter, .dialog-viewshed .tag::first-letter {
    text-transform: uppercase;
}
.viewshed-modal-heading {
    margin-top: -10px;
    display: flex;
}
.viewshed input[type=checkbox], .viewshed-list-view input[type=checkbox] {
    margin-left: -12px;
    display: block;
    position: absolute;
    z-index: 10;
}
.viewshed-illustration {
    width: 92%;
    margin-left: 4%;
    margin-top: 5%;
}
.viewsheds-content {
    margin: 0px -5px;
}
.tool-options .viewsheds-container .option:nth-child(1) {
    margin-right: 24px !important;
}
.viewsheds-container {
    margin: 0 !important;
    padding: 2px 10px 2px 10px !important;
    display: flex;
}
.actions button.sy.icon > img {
    width: 15px;
    margin-left: -1.75px;
    margin-top: -1px;
}
.viewsheds-auto #density-spinner {
    width: 32px !important;
    padding: 5px;
}
.viewsheds-auto .viewshed-filters {
    padding: 10px 0px 5px 0px;
}
.viewsheds-auto .roof-density-spinner {
    float: left;
}
.viewsheds-auto .input-wrapper.first {
    float: left;
}
button.sy.generate-auto-viewsheds {
    padding: 7px 27px;
    margin: 0px 4px 10px 0px;
    float: left;
}
button.sy.delete-all-auto-viewsheds {
    background-color: var(--red);
    padding: 7px 27px;
    margin: 0px 4px 10px 0px;
}
.delete-all-viewsheds-container {
    display: flex;
    width: 100%
}

.viewshed-info .option:nth-child(1) {
    font-weight: bold;
}
.viewshed-info .annotation-actions {
    display: none;
}
.viewshed-info .annotation-actions .add,
.viewshed-info .annotation-actions .a-remove {
    display: none;
}
.viewshed-info .annotation-actions .add.on,
.viewshed-info .annotation-actions .a-remove.on {
    display: block;
}
.viewshed-thumbnail {
    flex: 1;
    position: relative;
    margin-bottom: 155px;
    margin-top: 20px;
    margin-left: -16%;
    /*animation: fadein .75s;
    -webkit-animation: fadeIn .75s;
    -moz-animation: fadeIn .75s;
    -o-animation: fadeIn .75s;
    -ms-animation: fadeIn .75s;*/
}
.viewshed-thumbnail .base {
    width: 100px;
    position: absolute;
    top: 13px;
    left: 15px;
}
.viewshed-thumbnail .svg {
    margin: 0;
    width: 130px;
    position: absolute;
    top: 0;
    left: 0;
}
.viewshed-enlarged .base {
    width: 63.65%;
    position: absolute;
    margin-left: 18.175%;
    margin-top: 2.65%;
    animation: fadein 1.5s;
    -webkit-animation: fadeIn 1.5s;
    -moz-animation: fadeIn 1.5s;
    -o-animation: fadeIn 1.5s;
    -ms-animation: fadeIn 1.5s;
}
.viewshed-enlarged .svg {
    width: 78%;
    margin-left: 11%;
    margin-top: -4.5%;
    position: absolute;
}
.dialog-viewshed .horizon-overlay {
    width: 60%;
    position: absolute;
    margin-left: 18%;
    margin-top: 5.5%;
    animation-delay: 1s;
    animation: fadein 1.5s;
    -webkit-animation: fadeIn 1.5s;
    -moz-animation: fadeIn 1.5s;
    -o-animation: fadeIn 1.5s;
    -ms-animation: fadeIn 1.5s;
}
.dialog-viewshed .horizon-graph {
    width: 75%;
    margin-left: 10.5%;
    margin-top: -2%;
    position: absolute;
}

@media only screen and (max-height: 825px) {
    .viewshed-enlarged .base {
        width: 49%;
        margin-left: 25.5%;
        margin-top: 4%;
    }
    .viewshed-enlarged .svg {
        width: 60%;
        margin-left: 20%;
        margin-top: -1.5%;
    }
    .dialog-viewshed .horizon-overlay {
        width: 45.5%;
        margin-left: 27.25%;
        margin-top: 5.8%;
    }
    .dialog-viewshed .horizon-graph {
        width: 57%;
        margin-left: 21.5%;
        margin-top: 0%;
    }
}
@media only screen and (max-height: 700px) {
    .viewshed-enlarged .base {
        width: 40%;
        margin-left: 30%;
        margin-top: 1%;
    }
    .viewshed-enlarged .svg {
        width: 49%;
        margin-left: 25.5%;
        margin-top: -3.5%;
    }
}
.viewshed .icon, .viewshed-list-view .icon {
    position: absolute;
    display: block;
    float: right;
    margin-top: -6.5px;
    width: 20px;
    height: 20px;
    padding: 6px;
}
.viewshed .btn-horizon, .viewshed-list-view .btn-horizon {
    margin-left: 75px;
}
.viewshed-list-view .btn-horizon, .btn-viewshed {
    z-index: 100;
}
.viewshed .btn-horizon.list-view, .viewshed-list-view .btn-horizon.list-view {
    margin-left: 82%;
}
#viewsheds img {
    border-radius: 50%;
}
button.sy.remove-viewsheds {
    background-color: var(--red);
    margin-left: 4px;
}

button.sy.remove-viewsheds:disabled {
    background-color: var(--gray);
}

button.sy.refresh-viewsheds, button.sy.remove-viewsheds {
    float: right;
    height: 30px;
    width: 30px;
    font-size: 15px;
    padding: 3px;
    vertical-align: middle;
}

.module-specs-tool-viewsheds {
    width: 100%;
}
.module-specs-tool-viewsheds tr:first-child {
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    background-image: linear-gradient(to right, var(--dark-gray) 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}
.module-specs-tool-viewsheds table tr {
    padding: 20px !important;
}
.module-specs-tool-viewsheds td {
    vertical-align: middle;
    width: 27px;
    padding: 2px;
    text-align: center;
}
.module-specs-tool-viewsheds td.direction {
    width: 47%;
}
.module-specs-tool-viewsheds td.keepout-type {
    width: 10px;
}
.module-specs-tool-viewsheds .icon {
    display: inline-block;
    width: 25px;
    text-align: center;
    vertical-align: middle
}
.viewsheds-auto .label {
    margin-block-end: 0rem;
    margin-block-start: 0.5rem;
    margin-top: 2px;
}
.viewsheds-auto > input {
    font-size: 11px;
    width: 10px;
    color: var(--dark-gray);
    margin: 8px 5px 0px 8px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    float: left;
}
.auto-viewsheds-header {
    width: 100%;
    display: flex;
}

.auto-viewsheds-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 10px;
}

.auto-viewsheds-container {
    display: flex;
    justify-content: space-between;
}

.auto-viewsheds-placement {
    flex-grow: 1;
}

.viewsheds-auto .input-wrapper {
    float: left;
}

.viewsheds-auto #auto-viewshed-density {
    max-width: 23px;
    margin-left: 12px;
    margin-top: 4px
}
.viewsheds-auto #density-spinner {
    max-width: 32px;
}
.viewsheds-auto .auto-viewshed-inputs {
    float: left;
}
.viewsheds-auto > .ui-spinner {
    margin-top: 2px;
}
.viewsheds-auto #spinner {
    width: 32px !important;
}
.viewsheds-auto .ui-spinner-disabled, .keepouts-auto .ui-button-disabled {
    background-color: var(--light-gray);
}
.viewsheds-auto .ui-slider-disabled > .ui-slider-handle {
    background-color: var(--dark-gray);
}
.viewsheds-auto .ui-slider-disabled > .ui-slider-range {
    background-color: var(--dark-gray);
}
.viewsheds-auto .ui-state-disabled {
    opacity: .5;
}
.viewsheds-auto .auto-viewshed-inputs {
    width: 100%;
}
.viewsheds-auto .ui-widget.ui-widget-content {
    margin-right: 5px;
}

.pvwatts-info {
    margin-top: 6px;
}
.pvwatts table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.pvwatts table td {
    border: 1px solid var(--dark-gray);
    padding: 5px;
}
.pvwatts table .annual td {
    border: none;
    color: var(--orange);
    font-weight: bold;
}
.manual-array-panel {
    display: none;
}
.manual-array-snap {
    display: none;
}
.manual-array-options {
    background: var(--gray);
    padding: 5px;
    margin-bottom: 10px;
}
.roof-planes .spacer {
    padding: 5px;
}
.section-viewsheds .actions {
    margin-top: 15px;
}
.shading-container .hidden {
    display: none;
}

@keyframes fadeIn {
    0%    { opacity:0; }
    100%  { opacity:1; }
}


/* div holding list or table view */
#viewshed-display-options {
    float: right;
}

/* Viewshed actions dropdown menu button */
button#show-viewshed-actions-dropdown {
    margin: auto;
    padding-left: 5px;
    background-color: transparent;
    color: var(--black);
}

.select-all-viewsheds {
    margin-left: 5px;
}

/* The container <div> - needed to position the dropdown content */
#viewshed-actions {
    position: relative;
    display: inline-block;
}

#viewshed-actions-list {
    padding: 5px;
    margin: 0px;
    list-style-type: none;
}

#viewshed-actions-list li {
    font-size: 1rem;
    padding: 5px;
}

/* Dropdown Content (Hidden by Default) */
#viewshed-actions-dropdown {
    display: none;
    position: absolute;
    background-color: var(--white);
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

/* Links inside the dropdown */
.viewshed-actions-dropdown-content li {
    text-decoration: none;
    display: block;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

.viewshed-bulk-actions {
    display: flex;
    align-items: center;
    border: 1px solid var(--light-gray);
    height: 30px;
    border-radius: 8px;
}

.viewshed-action {
    border: none;
    display: flex;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: var(--white);
}

.viewshed-action:hover {
    background-color: var(--light-gray);
}

.viewshed-action:disabled {
    color: var(--gray);
    cursor: not-allowed;
    background-color: var(--white);
}

#remove-viewsheds:enabled {
    background-color: var(--red);
    color: var(--white);
}

.viewshed-action-text {
    padding-left: 10px;
}

.viewshed-action-icon {
    padding-top: 2.5px;
}

#viewshew-view-actions {
    display: flex;
    justify-content: space-between;
    height: 30px;
}

.viewshed-options-container {
    display: flex;
    gap: 1rem;
}

div#displayed-value-options {
    display: block;
}

#displayed-value-options .sw-multiway {
    margin: 0;
}

#displayed-value-options .sw-multiway.disabled .sw-option {
    cursor: not-allowed !important
}

#displayed-value-options .sw-multiway .sw-option, .sw-option.active {
    min-width: 3rem;
    line-height: 1rem;
}

#displayed-value-options .sw-multiway .sw-option > i {
    padding: 0 3px;
}
