.occluder-section > input {
    width: 30px;
    font-size: 11.5px;
    margin-left: 17px;
    color: var(--dark-gray);
    margin-top: 13px;
    padding: 5px 10px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.occluder-section .label {
    float: left;
    margin-right: 5px;
}
.occluder-section .model-btn {
    margin-right: 6px;
    margin-bottom: 7px;
    background-color: var(--gray);
}
.occluder-section .model-btn.active {
    background-color: var(--main-blue);
}
.occluder-heading h3 {
    float: left;
}
.occluder-heading .info {
    float: left;
    padding-top: 10px;
    margin-left: -8px;
}
.occluder-heading {
    padding-bottom: 35px;
    width: 100%;
}
.occluder-section {
    width: 100%;
    padding-top: 15px;
}
.occluder-section #occluder-opacity-slider {
    width: 120px;
    margin: 10px 0 0 7px;
    margin-top: 28px;
    float: left;
    border: var(--main-blue);
}
.occluder-section button.sy#occluders-add {
    padding: 8px;
    margin-top: 5px;
    width: 100%;
}
.occluder-section .sq-btn-container {
    float: left;
    width: 55px;
    text-align: center;
    font-size: 11px;
    opacity: .5;
}
.occluder-section .sq-btn-container.active {
    color: var(--main-blue);
    opacity: 1;
}
.occluder-section .sq-icon-btn {
    width: 40px;
    height: 40px;
    margin: 7px 0px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--white);
    border: 1px solid var(--gray);
    color: var(--dark-gray);
}
.occluder-section .sq-icon-btn:hover {
    cursor: pointer;
}
.occluder-section .sq-icon-btn i {
    font-size: 20px;
    margin-top: 1.5px;
    color: var(--dark-gray)
}
.occluder-section .sq-icon-btn img {
    width: 20.5px;
    margin-top: 3.75px;
    margin-left:-1.25px
}
.occluder-section .active > .sq-icon-btn {
    border: 1px solid var(--main-blue);
    background-color: var(--main-blue);
    color: var(--white);
    opacity: 1;
}
.occluder-section .active > .sq-icon-btn i {
    color: var(--white);
}
.occluder-section .active > .sq-icon-btn img {
    filter: brightness(100);
}