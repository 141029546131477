#moduleDropdown .dd-select {
    border: 0.5px solid var(--light-gray);
    border-radius: 12px;
    box-sizing: border-box;
}
#moduleDropdown .dd-select a {
    height: 40px;
    font-weight: bold;
}
#moduleDropdown .dd-selected-image {
    height: 40px;
}
#moduleDropdown .dd-options {
    height: 330px !important;
    border-radius: 4px;
}

.moduleDropdown {
    padding: 10px 0;
}


.toggle-box h4 {
    margin: 0;
}
.toggle-box .heading {
    display: flex;
    align-items: center;
}
.toggle-box :last-child {
    margin-bottom: 0;
}
.toggle-box .toggle {
    margin: 0 1em -5px 1em;
}



.module-specs-tool-options .row-spacer {
    height: 3px;
}
.module-specs-tool-options .col1 {
    float: left;
    width: 190px;
}
.module-specs-tool-options .col1 input[type="text"] {
    width: 90px;
}
.module-specs-tool-options input[type="radio"] {
    margin-top: -1px;
    vertical-align: middle;
}
.module-specs-tool-options .col2 {
    float: left;
    margin: 20px 0 5px;
}
.module-specs-tool-options .preview {
    width: 80px;
}
.odule-odule-specs-tool-actions {
    line-height: 2;
    margin: 10px 0 10px 1px;
}
.module-specs-tool-arrays {
    display: none;
}
.module-specs-tool-arrays ul {
    margin: -44px 0px 0px 6px;
    padding: 5px 0;
}
.module-specs-tool-arrays ul li {
    list-style: none;
    margin: 3px 0;
}
.module-specs-tool-actions {
    display: flex;
    justify-content: space-between;
}
.input-module-specs-tool-roof-offset {
    width: 75px !important;
}
.input-module-roof-mount {
    margin-left: 0;
}
.module-specs-tool-options label.second {
    margin-left: 10px;
}
.vertical-inputs input {
    margin-left: 0;
}
.vertical-inputs label {
    display: block;
    margin-bottom: 0.5em;
}
.snap-content {
    display: flex;
}
.snap .content label {
    display: contents;
}
.ground-mount-options {
    display: none;
    width: 100%;
    margin: 0;
}
.module-specs-tool-options table caption {
    text-align: left;
}
.segment-name-edit:hover,
.segment-remove:hover {
    /* color: #ebedee; */
    color: var(--dark-gray) !important;
}
.segment-name-edit,
.segment-remove {
    position: relative;
    overflow: hidden;
    background: transparent;
    border: none;
    padding: 1%;
    color: var(--dark-gray) !important;
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    padding: 0 1.5px 0 1.5px;
    font-size: 12px !important;
}
.segment-totals {
    position: relative;
    margin: 2px 0 -24px 0px;
}

.segment-dropdown {
    float: right;
    position: relative;
    z-index: 1000;
    width: 175px;
}
.segment-dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--dark-gray);
    min-width: 83%;
    z-index: 1;
    margin-top: 36px;
    margin-left: 10%;
    z-index: 999;
    border-radius: 6px;
}
.segment-dropdown-content a {
    color: var(--white);
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.segment-dropdown-content a:hover {
    background-color: #404142;
    transition: all .25s ease-in-out;
    border-radius: 0px;
}
.segment-dropdown-content a:first-child:hover {
    border-radius: 6px 6px 0px 0px;
}
.segment-dropdown-content a:last-child {
    padding-bottom: 8px;
}
.segment-dropdown-content a:last-child:hover {
    border-radius: 0px 0px 6px 6px;
}
.segment-dropdown-content .disabled {
    cursor: not-allowed;
}
/* end of Dropdown Button */

/* end of Auto Segments */

.layers-accordion {
    margin-bottom: 5px;
    display: none;
}
.layers-accordion input {
    padding: 0;
    margin: 0;
}
.layers-accordion .c-wrap,
.layers-accordion .l-wrap {
    float: left;
}
.layers-accordion .c-wrap {
    width: 20px;
    margin-left: 3px;
}
.input-snap-modules {
    margin: 0 0 0 4px !important;
}
.array-tab .dd-option-img {
    max-width: 40px;
}
.array-tab .dd-selected img {
    max-width: 20px;
    padding: 0px;
}
.array-tab .dd-options {
    width: 32vw !important;
    height: 52vh !important;
    top: 10vh;
    position: fixed;
    display: none;
}
.array-tab .snap input {
    margin: 0px;
    width: auto;
}
.array-tab .snap {
    font-weight: normal;
    font-size: 10px;
    /* width: 100%; */
    padding: 4px 50px 24px 0px;
}

.manual-array .snap .row {
    margin-bottom: 6px;
}
.manual-array .snap input[type=checkbox] {
    position: relative;
    bottom: 1px;
    margin-right: 8px;
}

.array-tab .fill,
.manual-array .fill {
    display: none;
    margin-top: 10px;
}
.array-tab .modify,
.manual-array .modify,
.manual-array {
    margin-top: 10px;
}
.array-tab .modify button,
.manual-array .modify button {
    margin: 0 3px 3px 0;
}
[id^=segment-area-] {
    margin: 4px 8px;
}
.tool-options input:disabled {
    background: var(--light-gray);
}

.disabled-tip span {
    display: none;
}

.disabled-tip,
.disabled-remove,
.disabled-move,
.disabled-copy {
    opacity: 1;
    display: inline;
}

.button-row {
    margin-bottom: 4px;
}

.array-tab .spacing-container,
.array-tab .array-container,
.array-tab .spacing-container .module-spacing,
.array-tab .module-options .move-options .orientation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-weight: strong;
}
.array-tab .spacing-container {
    margin-top: 4px;
    justify-content: left;
}
.array-tab .spacing-container .sub-container {
    margin-left: 20px;
}
.array-tab .spacing-container .array-height-container {
    margin-left: 15px;
}


.autofill {
    display: flex;
    align-items: center;
}
.autofill > * {
    flex: 1;
    text-align: center;
}
.autofill .tippy-box {
    text-align: left;
}
.autofill h4 {
    margin: 0;
    text-align: left;
}
.autofill select {
    padding: 5px;
    border-radius: 6px;
    border: 0.5px solid var(--light-gray);
    flex: 1.618;
    color: var(--dark-gray);
}

.racking-type {
    display: flex;
}
.racking-type img {
    height: 28px;
}
.racking-type .racking {
    flex: 1;
    text-align: center;
    margin-top: 10px;
}
.racking-type .caption {
    margin-top: 5px;
}

.module-spacing {
    display: flex;
    text-align: center;
}
.module-spacing h4 {
    line-height: 2;
    text-align: left;
}
.module-spacing > * {
    flex: 1;
}

.array-height {
    display: flex;
    justify-content: center;
    text-align: center;
}
.block.array-height {
    margin-bottom: 0;
}
.array-height img {
    width: 43px;
}
.array-height > * {
    flex: 1;
}
.array-height > .input-icon {
    flex: 1.5;
}
.array-height img {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
}
.array-height h4 {
    line-height: 2;
    text-align: left;
}

.ground-mount .row {
    display: flex;
    margin: 5px 0;
    align-items: center;
}
.ground-mount .row > * {
    flex: 1;
}
.ground-mount .row > .text {
    flex: 1.6;
}
.ground-mount input {
    width: 30px;
}

.builder-mode .toggle-container > * {
    flex: 1;
    text-align: center;
}
.builder-mode h4 {
    margin: 0 5px 0 0;
    display: inline-block;
}
.builder-mode .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.builder-mode .toggle {
    margin-bottom: -4px;
    flex: 0;
}
.builder-mode .qmark-info {
    margin: 0;
}

.module-orientation {
    display: flex;
    align-items: center;
}
.module-orientation h4 {
    margin: 0 15px 0 0;
}
.module-orientation .toggle {
    margin: 0 0 -5px 5px;
}

.array-tools h4 {
    margin: 10px 0;
}
.array-tools .button-row {
    display: flex;
}
.array-tools button.sy {
    flex: 1;
    background: none;
    border: 0.5px solid var(--dark-gray);
    color: var(--dark-gray);
    font-weight: normal;
    margin-right: 5px;
    text-align: center;
}
.array-tools button.sy:last-child {
    margin: 0;
}
.array-tools button.sy:disabled {
    background-color: var(--white);
    color: var(--blue-gray);
    border: 0.5px solid var(--blue-gray);
    box-shadow: none;
}

.snap-modules {
    display: flex;
    align-items: center;
}
.snap-modules h4 {
    margin-right: 5px;
}

.array-tab .details-container {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 6px;
    align-items: center;
    justify-content: space-between
}

.array-tab .array-container .sub-container {
    margin: 2px 0px;
}

.array-tab .array-container {
    padding:0px 4px;
}
.array-tab .sub-container span {
    color: var(--dark-gray);
}
.array-tab .sub-container {
    margin: 2px 4px 9px 4px;
}
.array-tab input {
    width: 24px;
    margin: 0px 4px;
}
input[type="radio"] {
    width: auto;
    margin-right: 10px;
}
.module-spacing {
    margin-top: 12px;
}
.array-tab .spacing-container .module-spacing span,
.array-tab .spacing-container .module-options span,
.array-tab .spacing-container .array-spacing span,
.array-spacing span,
.array-layout span,
.img-block span {
    font-weight: normal;
}

.array-spacing ,
.array-layout {
    padding: 4px 0px;
}
input[type=radio],
input[type=checkbox] {
    cursor: pointer;
}
.array-details-container,
.viewsheds-container,
.array-builder-mode {
    background: var(--very-light-gray) !important;
    margin: 4px 0px;
}
.array-tab .module-options {
    /* height: 90px; */
    height: 48px;
    padding: 2px 0 6px 0;
}
.array-tab .module-options .move-options span {
    font-weight: normal;
}
.orientation {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 3px;
    align-items: center;
}
.orientation-sub-container {
    display: flex;
    align-items: center;
    width: 82px;
}
.array-tab .module-options .move-options,
.array-module-mode {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.array-module-mode {
    margin-top: 9px;
}

.learn-more-dialog .row-container .bullets {
    /* font-size: 12px; */
    color: var(--black);
}

.learn-more-exit {
    position: absolute;
    left: 2%;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.learn-more-dialog .row-container .title {
    font-weight: bold;
    font-size: 26px;
    color: var(--main-blue);
    margin: 24px 0 0 0;
}
[id^="learnMore-"] {
    background: var(--main-blue);
    display: flex;
}
#learnMore,
#learnMore-2,
#learnMore-3,
.learn-more-dialog {
    background: var( --blue-gray);
}
.builder-mode-img {
    width: 45%;
    /* width: 95%; */
    margin: 44px 0 0 0;
}
button.mode-switch-btn {
    align-items: center;
    color: var(--white) !important;
    font-size: 12px !important;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    border: none !important;
    margin: 4px 13px 0 6px !important;
}
button.mode-switch-blue {
    background: var(--main-blue) !important;
}
button.mode-switch-green {
    background: var(--green) !important;
}
.ui-dialog-buttonset {
    text-align: center !important;
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: none;
}
.learn-more-btn {
    border: none !important;
    width: 180px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white) !important;
    font-size: 20px !important;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    background: var(--main-blue) !important;
}

.learn-more-dialog .ui-dialog .ui-dialog-buttonpane {
    display: flex;
    justify-content: center;
}

/* $("#learnMore").siblings(".ui-dialog-buttonpane") */

/* .ui-dialog .ui-dialog-buttonpane { */

/* #learnMore + .ui-dialog .ui-dialog-buttonpane,
#learnMore-2 + .ui-dialog .ui-dialog-buttonpane,
#learnMore-3 + .ui-dialog .ui-dialog-buttonpane { */
.button-pane-custom {
    text-align: left !important;
    border-width: 0 !important;
    background-image: none !important;
    margin-top: 0 !important;
    padding: 0 !important;
}

#dialog-confirm-mode-1,
#dialog-confirm-mode-2 {
    text-align: center;
    margin: 8px 0 0 0;
    font-weight: bold;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.button-set-custom {
    position: absolute !important;
    bottom: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    display: flex !important;
    flex-direction: row-reverse !important;
}


.row-container .page {
    position: absolute;
    top: 2%;
    left: 92%;
    font-size: 16px;
    font-weight: bold;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.row-container .gif {
    margin: 40px 0 0 0;
    width: 95%;
}

.learn-more {
    margin-left: 4px;
    color: var(--dark-gray);
    font-size: 12px;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
}
.no-close .ui-dialog-titlebar-close,
.no-close .ui-dialog-titlebar {
    display: none;
}
.row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.bullets {
    width:78%;
    text-align: center;
    font-size: 16px;
    /* margin: 12px 0 0 0; */
    display: flex;
    align-items: center;
    color: var(--dark-gray) !important;
}

.array-details-title {
    padding: 5px 6px 4px;
}
.module-orientation {
    padding-top: 1px;
    margin-bottom: 3px;
}
.array-tab,
.array-details-title .text {
    font-weight: bold;
    font-size: 12px;
    color: var(--dark-gray);
}
.shade-title {
    width: 350px;
}

.array-builder {
    margin: 0 0 5px 0;
    background: var(--very-light-gray);
    padding: 0 0 6px 0;
}

.auto-fill-label {
    font-weight: normal;
    margin-left: 6px;
}
.details-visibility,
.module-specs-tool-mount-type {
    display: flex;
    align-items: center;
    padding: 2px 0px;
}

.img-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-weight: normal; */
}
.module-mode {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: 2px solid var(--yellow);
    margin: 0px 4px 4px 2px;
}

.roof-plane-dim-label {
    box-shadow: 0 1px 4px var(--black-transp);
}
.tilt-az-container tbody {
    display: inherit;
}
.tilt-az-container {
    display: flex;
    flex-direction: row;
}

.segment-tilt-az {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px 0 4px;
    text-transform: uppercase;
    font-weight: bold;
}
input.segment-header {
    background-color: inherit;
    color: inherit;
    text-transform: uppercase;
    padding: 0;
}
.mode-option input {
    margin-top: -2px;
}
.mode-option input[type="radio"] {
    margin-right: 8px;
    margin-left: 9px;
}
.mode-option span {
    font-size: 10px;
    font-weight: normal;
    color: var(--dark-gray);
}
.mode-option {
    left: 4px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}
.mode-container,
.mode-helper {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.flash {
  animation: flash 2s ease-out;
  animation-iteration-count: infinite;
}
@keyframes flash {
    0% {
        background-color: transparent;
        box-shadow: none;
    }
    50% {
        background-color: #ff006699;
        box-shadow: 0px 0px 27px 2px #FF006699;
    }
    100% {
        background-color: transparent;
        box-shadow: none;
    }
}

