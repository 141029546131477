.nav a {
    color: inherit;
    text-decoration: none; 
    cursor: pointer;
    opacity: 0.60;
}

.nav a:target {
    opacity: 1 !important;
}

.nav {
    list-style: none;
    display: flex;
    color: var(--white); 
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    list-style: none;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 26px;
    text-transform: uppercase;
}

.modal-content .descriptions .info {
    width: 160px;
    white-space: normal;
}

.modal-content .descriptions .controls .section-title img {
    margin-left: 5%;
}

.modal-content .descriptions .controls .section-title {
    text-transform: uppercase;
    font-weight: bold;
    /* margin: 4% 0 1% 0; */
    margin: 2% 0 1% 0;
    border-bottom: 1px solid rgba(255,255,255,.17);
}

.modal-content .descriptions .controls .container {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    box-sizing: content-box; 
}
.modal-content .descriptions .controls {
    width: 54%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -10%;
    height: 76vh;
    overflow-y: auto;
    overflow: hidden;
    box-shadow: none;
}
.modal-content .descriptions .info {
    font-size: 14px;
}
.modal-content .descriptions {
    position: relative;
    /* top: 34%; */
    top: 20%;
}
.modal-content .descriptions .basics {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 24px;
    position: relative;
    top: 100px;
}
.modal-content .descriptions div[class^="option-"] i {
    margin-bottom: 5%;
}
.modal-content .descriptions div[class^="option-"] {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0% 10%;
    text-align: center;
}

.modal {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, .85);
    transition: all ease 0.2s;
    color: var(--gray);
}

.modal-content {
    display: none;
    overflow-y: auto;
    position: relative;
    height: 100vh;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.grid-container .item {
    padding: 1%;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: ". ." ". ." ". .";
  }

.grid-container-two {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". ." ". .";
  }
  
.basics .title {
    font-weight: bold;
}
   
.exit:hover {
    cursor: pointer;
}

.exit {
    position: absolute;
    left: 95%;
    top: 3%;
    color: var(--white);
}
