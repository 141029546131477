#dialog-project-info .content {
    display: none;
}
#dialog-project-info {
    overflow-y: auto;
}
#dialog-project-info h2 {
    color: var(--active-font-color);
    margin: 10px 0;
    float: left;
}
.dialog-project-info .input-container {
    width: 404px;
    margin: 0 auto;
}
.dialog-project-info .input-container.bottom {
    margin-bottom: 45px;
}
#pp-info-tabs-info input {
    width: 186px;
    padding: 5px;
    height: 19px;
    box-sizing: border-box;
    height: 29px;
}
#pp-info-tabs-info .monthly-usage input {
    width: 65px;
}
#pp-info-tabs-info .monthly-usage .info {
    float: left;
    margin-top: 12px;
}
#pp-info-tabs-info .monthly-usage .info a {
    color: white;
}
#pp-info-tabs .tags {
    margin-top: 20px;
    margin-bottom: 5px;
}
.tag#annual-consumption {
    background-color: var(--main-blue);
}
.tag#annual-production {
    background-color: var(--teal);
}
.tag#asa-percentage {
    background-color: var(--light-blue);
}
.tag#pv-system-offset {
    background-color: var(--light-purple);
}
.dialog-project-info select {
    width: 198px;
    height: 100%;
}
.dialog-project-info .select-container {
    height: 31px;
    float: left;
    margin-right: 4px;
    margin-top: -4px;
}
button.project-info {
    margin-top: -2px;
}
.pvwatts-logo {
    margin-top: 15px;
}
.pvwatts-logo img {
    width: 150px;
}
#input-site-info-state {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: var(--dark-gray);
}
#input-site-info-state:disabled {
    background-color: var(--very-light-gray);
    cursor: not-allowed;
}
.loading {
    text-align: center;
    padding: 100px;
}
.no-content {
    text-align: center;
    padding: 100px;
    display: none;
    font: 12px "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.current-monthly-usage input {
    text-align: right;
}
.project-info-accordion {
    margin-bottom: 5px;
    display: none;
}
.project-info-accordion table {
    width: 100%;
}
.project-info-accordion .tool-options > table {
    margin: 5px 0;
}
.pp-info-actions td {
    padding-top: 5px;
    width: 300px !important;
}
.project-btns-wrapper button {
    margin-right: 2px;
}
.project-btns-wrapper {
    display: flex;
    align-items: center;
}
.pp-info-col {
    width: 50%;
    vertical-align: top;
}
#show-pp-info-dialog {
    height: 28px;
}
.take-screenshot,
.set-profile-image {
    width: 34px;
    height: 28px;
}
.take-screenshot .fa,
.set-profile-image .fa {
    font-size: 16px;
}
.pp-info-img-col {
    text-align: left;
    vertical-align: top;
}
.pp-info-img-col .pp-info-img {
    background: #d0d3d5;
    border-radius: 0;
}
.pp-info-img-col button {
    margin: 10px 0;
}
.pp-info-img {
    height: 100px;
    border-radius: 3px;
    position: relative;
}
.pp-info-img.empty {
    background: #d0d3d5;
}
.pp-info-img .crop {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}
.pp-info-img .crop a {
    color: var(--white);
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
}
.pp-info-img:hover .crop {
    display: block;
}
.pp-info-img.empty:hover .crop {
    display: none;
}
#pp-info-tabs {
    border: none;
    padding: none;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
#pp-info-tabs-info table {
    width: 100%;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
#pp-info-tabs-info table input, select {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
#pp-info-tabs-info td {
    vertical-align: top;
    color: var(--label-gray);
    padding: 2.5px 0px;
}
#pp-info-tabs-info .monthly-usage {
    width: 45%;
    padding-right: 5%;
    padding-left: 1%;
}
#pp-info-tabs-info .customer-details {
    width: 95%;
    margin-bottom: 2%;
    padding-top: 6px;
}
#pp-info-tabs-info .customer-details td {
    padding-bottom: 5px;
}
#pp-info-tabs-info .customer-details input {
    width: 100%;
}
#pp-info-tabs-info .customer-details .site-info-heading {
    margin: 8px 0;
}
.dialog-project-info #pp-info-tabs-info select {
    width: 100%;
}
.dialog-project-info #pp-info-tabs-info .select-container {
    width: 100%;
    float: none;
    margin-top: 0;
    height: 29px;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
    color: var(--white) !important;
    background: var(--main-blue);
    border: 1px solid var(--main-blue);
    border-radius: inherit;
}


.tilt-az-container .extra-info-specs,
.tilt-az-container tr .extra-info-specs,
.extra-info-az-tilt {
    position: absolute !important;
    margin-left: 190px !important;
    margin-top: -2px !important;
}

#input-site-info-state-menu {
    height: 400px;
}
.empty-tab {
    text-align: center; 
    margin-top: 10%;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.empty-tab p {
    font-size: 14px;
}
.empty-tab .viewshed-illustration {
    width: 65%;
    margin-left: -2.5%;
    margin-top: 10%;
}
.extra-info-specs p {
    position: fixed !important;
    left: 10vw;
    top: 40vh;
}
#pp-info-tabs ul {
    background: var(--white);
    border: 0px;
    margin-left: 12px;
}
#pp-info-tabs {
    margin: 7px 0 20px 0;
    width: 100%;
    border-radius: 12px;
    border: none;
    color: var(--dark-gray);
    background: var(--white);
    line-height: 1.3;
}
#pp-info-tabs-info .electric-bill h3 {
    margin: 15px 0;
    float: left;
}
#pp-info-tabs-info .electric-bill table {
    width: 180px;
}
#pp-info-tabs-info .electric-bill .monthly,
#pp-info-tabs-info .electric-bill .yearly {
    text-align: right;
}

#pp-info-tabs-chart .content > table td {
    vertical-align: top;
}

#pp-info-tabs-chart .content > table td i {
    color: var(--gray);
    margin-right: 7px;
    font-size: 14px;
}

#pp-info-tabs-chart .charts h2 {
    margin: 20px 0 0 0;
}
#pp-info-tabs-chart .msa-chart-col {
    width: 15%;
    padding-right: 10%;
}
#pp-info-tabs-chart .mp-chart-col {
    width: 15%;
    padding-right: 10%;
}

#msa-chart svg, #mp-chart svg {
    width: 82%;
    margin-left: 9%;
}
#pp-info-tabs-chart table {
    width: 100%;
    border-collapse: collapse;
}
#pp-info-tabs-chart thead td {
    font-size: 14px;
    font-weight: 900;
    color: var(--dark-gray) !important;
}
#pp-info-tabs-chart thead {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    background-image: linear-gradient(to right, var(--dark-gray) 15%, rgba(0, 0, 0, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}
#pp-info-tabs-chart .module-info {
    margin-top: 20px;
}
#pp-info-tabs-chart .module-info td {
    padding: 10px 5px;
    text-align: left;
    color: var(--label-gray);
}
#pp-info-tabs-chart .generate-report-wrapper {
    margin: 15px 0 0 30px;
}
.pp-info-tabs-segment .inner {
    padding-left: 30px;
    margin-left: 30px;
}
.pp-info-tabs-segment table {
    width: 100%;
}
.pp-info-tabs-segment td {
    vertical-align: top;
    color: var(--label-gray);
    padding: 2.5px 0px;
}
.pp-info-tabs-segment .system-parameters {
    width: 40%;
    padding-left: 1%;
    float: left;
}
.pp-info-tabs-segment .system-parameters h2 {
    margin-left: 0px;
}
.pp-info-tabs-segment .system-parameters input[type=text] {
    width: 40px;
    text-align: right;
}
.pp-info-tabs-segment .system-parameters select {
    width: 160px;
}
.pp-info-tabs-segment .solar-access h2 {
    margin-left: 60px;
    text-align: center;
}

.pp-info-tabs-segment .stats {
    line-height: 1.5;
}
.pp-info-tabs-segment .stats table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 25px;
}
.pp-info-tabs-segment .stats thead td {
    font-size: 14px;
    font-weight: 900;
    line-height: 18px;
    background-image: linear-gradient(to right, var(--dark-gray) 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
    color: var(--dark-gray);
}
.pp-info-tabs-segment .stats td {
    padding: 5px;
    text-align: left;
    color: var(--label-gray);
}
.sa-chart {
    width: 80%;
}
.pp-info-tabs-segment .sa-chart svg {
    width: 100%;
    height: 100%;
}
.pp-info-tabs-segment .chart {
    width: 80%;
}

.pp-info-tabs-segment .solar-access {
    width: 59%;
    float: left;
}
#pp-info-tabs hr {
    margin: 5% 0%;
}
#pp-info-tabs .actions button {
    margin-right: 5%;
    padding: 7px 8%;
}
#pp-info-tabs .actions {
    margin-top: 7%;
}
.pp-info-viewsheds table {
    border-collapse: collapse;
    margin: 20px 0 0 0;
}
.pp-info-viewsheds thead td {
    font-weight: 900;
    color: var(--dark-gray);
    line-height: 20px;
    background-image: linear-gradient(to right, var(--dark-gray) 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}
.pp-info-viewsheds td {
    padding: 8px 5px;
    text-align: left;
}
.weather-station-text {
    padding-top: 1%;
    padding-bottom:  3%;
    color:  var(--label-gray);
}
#electric-after-chart {
    padding-left:  5%;
    padding-right: 12%;
    padding-bottom:  5%;
    margin-top: -5%;
}
#electric-before-chart {
    margin-left: 2%;
    padding-right:  5%;
}

.please-wait {
    margin: 15px 0 0 30px;
    display: none;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
#pp-info-tabs-info td.rate-label {
    padding-top: 28.5px;
}
.stats .viewsheds-stats {
    position:  absolute;
    left: 78%;
    width: 20%;
    padding-top: 12%;
    line-height: 20px;
}
