.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.loading-screen .container {
    margin-top: 23vmin;
    width: 30%;
}
.loading-screen .outer {
    width: 100%;
    height: 5px;
    border: 1.25px solid #343434;
    border-radius: 50px;
    padding: 1px;
}
.loading-screen .inner {
    display: flex;
    height: 100%;
    width: 100%;
    /*box-shadow: inset 0px 0px 0px 1px #999;*/
    border-radius: 50px;
    overflow: hidden;
    /*background: #555;*/
}
.loading-screen .inner .bar {
    display: flex;
    height: 100%;
    width: 0;
    background: #eee;
}
.loading-screen .description {
    font-size: 14px;
    color: #eee;
    text-align: center;
    margin-top: 10px;
}

.loading-screen .bar.determinate {
    animation: progress-animation1 5s ease-in-out;
}
.loading-screen .bar.indeterminate {
    width: 50%;
    animation: progress-animation2 2s cubic-bezier(0.0, 0.0, 1.0, 1.0) infinite;
}
@keyframes progress-animation1 {
    0%   {width:  0%;}
    20%  {width: 10%;}
    40%  {width: 30%;}
    50%  {width: 60%;}
    100% {width: 90%;}
}
@keyframes progress-animation2 {
    0%   { margin-left: -50%; }
    100% { margin-left: 100%; }
}