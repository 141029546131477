.switch-input {
    display: none;
}
.switch-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    padding: 10px 5px 10px 37px;
}
.switch-label:before, .switch-label:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.switch-label:before {
    left: 1px;
    width: 33px;
    height: 18px;
    background-color: var(--gray);
    border-radius: 20px;
}
/* knob */
.switch-label:after {
    left: 3px;
    width: 14px;
    height: 14px;
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: -1px -1px 2px -1px rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.switch-label .toggle--on {
    display: none;
}
.switch-label .toggle--off {
    display: inline-block;
}
.switch-input:checked + .switch-label:before {
    background-color: var(--main-blue);
}
.switch-input:checked + .switch-label:after {
    left: 1px;
    background-color: #FAFAFA;
    -ms-transform: translate(120%, -50%);
    -webkit-transform: translate(120%, -50%);
    transform: translate(120%, -50%);
}
.switch-input:checked + .switch-label:before {
    background-color: var(--main-blue);
}
.switch-input:checked + .switch-label .toggle--on {
    display: inline-block;
}
.switch-input:checked + .switch-label .toggle--off {
    display: none;
}
.switch-option-unchecked {
    display: inline-block;
    margin: 0 5px 0 0;
}



/* original toggle
.switch-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    padding: 10px 0 10px 35px;
}
.switch-label:before, .switch-label:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.switch-label:before {
    left: 1px;
    width: 25px;
    height: 8px;
    background-color: var(--gray);
    border-radius: 8px;
}
.switch-label:after {
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}*/