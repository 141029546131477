button.sy.export-btn { 
    width: 150px; /* come back to */
    margin: 0px 2.5px;
}

.export-container {
    display: flex; 
    justify-content: space-between; 
    margin: 15px 3%;
}

.export-container-ortho,
.export-container-surface-model {
    display: flex; 
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
}

.exports-content {
    margin-top: 10px;
}

.export-line {
    width: 94%;
    margin-left: 3%;
}

.export-title {
    font-size: 14px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;

}

.export-tag-line {
    font-size: 10.5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#export-type {
    width: 96%;
    margin-left: 2%;
    border-width: 2px;
    background-position: 99%;
    font-size: 13px;
    padding: 6px 10px;
    margin-top: 6.5px;
    margin-right: 10px;
    border-width: 1px;
}

#export-type:hover {
    cursor: pointer;
}

.save-tip-box {
    background: var(--very-light-gray);
    padding: 2% 4%;
    color: var(--dark-gray);
    position: relative;
    width: 88%;
    left: 2%;
    border-radius: 8px;
    font-size: 10.5px;
}

.save-tip-box span {
    font-weight: 900;
    font-size: 14px;
    line-height: 23px;
}

.segment-list {
    overflow: scroll;
    height: 53vh;
}


@media only screen and (max-height: 850px) {
    .segment-list {
        height: 40vh;
    }
}

.segment-list .check-row {
    width: 88%;
    margin-left: 2%;
}

.segment-list .check-row:hover {
    background: #efefef;
    color: var(--dark-gray);
    border-radius: 6px;
    cursor: pointer;
}

.segment-list-key {
    text-align: right;
    width: 89%;
    margin: 20px 2.5%;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: var(--blue-gray);
}

.segment-list-key .fa-check-circle {
    color: #27E2A4;
}

.selected-segment {
    background: var(--very-light-gray);
    color: var(--active-font-color) !important;
    border-radius: 4px;
    cursor: pointer;
}









/* from viewshedstool.css */
.shade-report-banner-image .row {
    display: flex;
    align-items: center;
    margin: 23px 0;
}
.shade-report-banner-image button {
    padding: 5px 23px;
}
.shade-report-banner-image .img-container {
    position: relative;
}
.shade-report-banner-image .banner-img {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid var(--very-light-gray);
}
.shade-report-banner-image .banner-img.placeholder {
    background: var(--very-light-gray);
    text-align: center;
    line-height: 100px;
    cursor: default;
}
.shade-report-banner-image .banner-img.placeholder img {
    width: 40px;
    opacity: .3;
    margin:0 auto;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}
.shade-report-banner-image .placeholder span {
    display: inline-block;
    vertical-align: bottom;
    line-height: normal;
    opacity: .5;
}
.shade-report-banner-image .banner-img.banner-annotations {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: none;
}
.shade-report-banner-image .annotate {
    background-color: var(--orange);
}
#dialog-annotation {
    overflow: hidden;
}
.dialog-annotation .actions {
    text-align: right;
    margin-top: 5px;
}

.shading-container .hidden {
    display: none;
}