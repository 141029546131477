.drone-img-thumbs {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px;
}
.drone-img-thumbs img {
    border-radius: 11.5px 11.5px 0px 0px;
    cursor: pointer;
    width: 100%;
}
.drone-img-thumbs .thumb {
    margin: 5px;
    border-radius: 12px;
    border: 1px solid var(--light-gray);
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 30%;
}
.drone-img-thumbs .thumb:hover {
    box-shadow: 0px 4px 20px var(--black-transp);
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.drone-img-thumbs .thumb.active {
    border: 1px solid var(--main-blue);
}
.drone-img-thumbs .name {
    padding: 0.5px 10px 5px 10px;
    contain: content;
}

#drone-img-zoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark-gray);
    z-index: 11000;
}

#drone-img-zoom .controls {
    height: auto;
    right: 10px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 50px;
    z-index: 151;
    margin: 0;
    padding: 10px;
    box-shadow: none;
    background: var(--dark-gray);
    border: 0.5px solid var(--main-blue);
    border-radius: 8px;
}
#drone-img-zoom button {
    width: 30px;
    height: 30px;
    margin: 10px 0 0 0;
    display: block;
    border-radius: 50%;
    border: 1px solid #999;
    font-weight: bold;
    color: #eee;
    background: none;
    font-size: 1.3em;
}
#drone-img-zoom button:hover {
    background: #3f4347;
    cursor: pointer;
}
#drone-img-zoom button:active {
    background: #555;
}
#drone-img-zoom .icon-plus {
    line-height: 1.2;
}
#drone-img-zoom .icon-minus {
    line-height: 1.4;
}
#drone-img-zoom button:first-child {
    margin-top: 0;
}

#drone-img-zoom .zoom-open {
    height: 100%;
    text-align: center;
    width: 100%;
}
#drone-img-zoom .zoom-open img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}
#drone-img-zoom .zoom-open:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
