#split-content-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

#split-content-wrapper .split-title {
    padding: 8px 12px;
    flex: 0 0 50px;
    height: 50px;
    line-height: 6px;
    box-shadow: inset 7px 0 9px -7px var(--black-transp);
}

#split-workspace-wrapper {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
}

#split-sidebar {
    min-width: 60px;
    z-index: 1100;
}

#split-main {
    width: 100%;
    position: relative;
}
#split-main .viewport {
    position: relative;
    height: 100%;
}
#container3d {
    height: 100%;
}
#container3d div {
    height: 100%;
}
#container3d canvas {
    width: 100%;
    height: 100%;
}

#split-sidebar .content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 7px 5px 10px;
    box-sizing: border-box;
}

.split .gutter-vertical {
    cursor: row-resize;
    margin: 0 7px 0 10px;
    border-radius: 5px;
    background: var(--blue-gray) url(../styles/images/icons/handle-dots.png);
    background-repeat: no-repeat;
    background-size: 12px 7.2px;
    padding: 2px 0px;
    background-position: center center;
}

/*.gutter-vertical {
    margin-top: -13px !important;
    position: relative;
    z-index: -1;
}*/

.split .gutter-horizontal {
    cursor: col-resize;
    background: var(--white);
}

.split .hbox,
.split .vbox {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
}

.split .vbox {
    flex-direction: column;
}

.split .hbox {
    flex-direction: row;
}

.split .vbox.centered,
.split .hbox.centered {
    align-items: center;
}

.split .vbox.reversed,
.split .hbox.reversed {
    justify-content: flex-end;
}

.split .expand {
    flex: 1 1 auto;
}

.split .fixed {
    flex: 0 0 auto;
}

.toolbar-vertical {
    background: var(--blue-gray);
    flex: 0 0 65px;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);*/
}

.toolbar-vertical button {
    margin-top: 8px;
}

.toolbar-vertical .reversed button {
    margin-top: 0;
    margin-bottom: 5px;
}

.nav-anchor {
    position: relative;
}
.nav-container {
    position: absolute;
    top: 68px;
    left: 0;
    z-index: 9;
    pointer-events: none;
}
