.ui-dialog {
    overflow: visible;
    padding: 0;
    border: 0;
    box-shadow: 10px 10px 32px var(--black-transp);
}
.ui-dialog.ui-widget.ui-widget-content {
    border: 0;
    border-radius: 12px;
    padding: 15px 22px;
}
.ui-dialog.ui-front {
    z-index: 2000;
}
.ui-widget {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-widget input, select {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 3px 4px;
    border-radius: 8px;
    color: var(--dark-gray);
}
.ui-widget select {
    border: 1px solid var(--light-gray);
}
.rename-dialog input {
    padding: 6px 8px;
}
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix.ui-draggable-handle {
    background-color: var(--white);
    color: var(--dark-gray);
    border: 0;
    border-radius: 5px;
    padding: 5px;
    margin: 15px 5px 5px 5px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-dialog .ui-dialog-content {
    padding: .15em 1em;
    margin-top: -.5em;
}
.ui-dialog .ui-dialog-title {
    font: 16px "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    width: auto;
    float: none;
    font-weight: bold;
}
.ui-widget button {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.ui-dialog .row {
    margin: 15px 0 10px 0;
    display: flex;
    align-items: baseline;
}
.ui-dialog .row .full-width {
    flex: 1;
}
.ui-dialog .desc {
    padding: 10px 0px 5px 0px;
}
.ui-dialog button.sy.rename, button.sy.start-over, button.sy.delete, button.sy.save {
    padding: 9px 28px;
}
.ui-widget-overlay {
    background: var(--black);
    opacity: .75;
    z-index: 1000;
}

/*.rename-dialog .ui-button.ui-dialog-titlebar-close {
    display: none;
 }*/

.ui-button.ui-dialog-titlebar-close {
    display: none;
}

.dialog-viewshed .ui-dialog-titlebar .ui-button.ui-dialog-titlebar-close {
    display: block;
}
/*#ui-id-21 .ui-dialog-titlebar {
    margin-top: -2px;
    margin-bottom: 10px;
}
#ui-id-21 .ui-dialog {
    padding-bottom: 10px;
}*/
.ui-button.ui-dialog-titlebar-close {
   background: var(--white);
   border-radius: 4px;
   border: 0px;
   margin-top: -3%;
}
.ui-button.ui-dialog-titlebar-close .ui-icon-closethick {
    background-image: none !important;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--label-gray);
    display: inline-block;
    text-indent: 0;
    white-space: normal;
    width: auto;
    height: auto;
    padding: 2px;
    color: var(--label-gray);
}
.ui-button.ui-dialog-titlebar-close .ui-icon-closethick:before {
    content: "\f00d";
}

.dialog .buttons {
    justify-content: flex-end;
}
.dialog .buttons button {
    text-align: center;
    margin-left: 10px;
}
.dialog .buttons button:first-child {
    margin-left: 0;
}

#save-as-dialog .ui-dialog-title {
    padding: 15px;
}

.dialog .rename,
.dialog .cancel {
    padding: 8px 20px;
    margin-top: 10px;
}
.dialog .ok {
    padding: 8px 40px;
    margin-top: 10px;
}
.dialog .delete {
    background: var(--red);
}