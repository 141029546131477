/* based on https://github.com/helion3/inspire-tree  */

.system-tree {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.system-tree,
.system-tree * {
    box-sizing: border-box;
}
.system-tree>ol {
    position: relative;
}
.system-tree ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
.system-tree ol ol {
    padding-left: 20px;
}
.system-tree .collapsed>ol {
    display: none;
}
/*.system-tree .folder:last-child:not(:only-child),
.system-tree>ol>.folder:first-child:not(:only-child),
.system-tree li .folder:last-child {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAMCAYAAACji9dXAAAAFUlEQVQIW2M8d+HSf0YGBgYGRpJZAJ2uFKcp4HfpAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
}*/
.system-tree li.expanded:not(:last-child)>ol {
    background-repeat: repeat-y;
}

.system-tree>ol>.folder:first-child:not(:only-child) {
    background-position: 11px 13px;
}
/*.system-tree>ol .expanded:not(:last-child):not(:first-child),
.system-tree li.expanded:not(:last-child)>ol,
.system-tree li:not(:last-child) {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAYCAYAAAA7zJfaAAAAFUlEQVQIW2M8d+HSf0YGBgYGRrqyABKeKU0SV+t2AAAAAElFTkSuQmCC);
    background-position: 11px 0;
}*/
.system-tree .leaf:not(:last-child):not(.detached) {
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAYCAYAAAAh8HdUAAAAN0lEQVQ4T2M8d+HSfyMDPUYGEgBJimHmMo7aBAmK0dCDJgl4ioClDGLo0dBDDz0SMu5o2oMFFgBXbEeI0XwYIwAAAABJRU5ErkJgggAA);
   */
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAYCAYAAAAh8HdUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFG2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNi4wLWMwMDYgNzkuZGFiYWNiYiwgMjAyMS8wNC8xNC0wMDozOTo0NCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnBob3Rvc2hvcD0iaHR0cDovL25zLmFkb2JlLmNvbS9waG90b3Nob3AvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0RXZ0PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VFdmVudCMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjQgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDIxLTA2LTA5VDE3OjA0OjQ4LTA1OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAyMS0wNi0xMFQwOToyNzozMi0wNTowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAyMS0wNi0xMFQwOToyNzozMi0wNTowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9maWxlPSJzUkdCIElFQzYxOTY2LTIuMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpkNTJhMzhkNC1iNGNlLTQ4MzMtYjJlZC1iNGM3MWM1M2QzZTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ZDUyYTM4ZDQtYjRjZS00ODMzLWIyZWQtYjRjNzFjNTNkM2U5IiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6ZDUyYTM4ZDQtYjRjZS00ODMzLWIyZWQtYjRjNzFjNTNkM2U5Ij4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDpkNTJhMzhkNC1iNGNlLTQ4MzMtYjJlZC1iNGM3MWM1M2QzZTkiIHN0RXZ0OndoZW49IjIwMjEtMDYtMDlUMTc6MDQ6NDgtMDU6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChNYWNpbnRvc2gpIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIaXN0b3J5PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pt9LXu0AAAAzSURBVDiNYzh34dJ/BrqAUZtGbYKD////MzDBbCKWPn/xMiNZNg230Bu1CQkw/v9PuusAUTdFzpErNBYAAAAASUVORK5CYII=);
    background-position: 11px 0;
    background-repeat: no-repeat;
    margin-top: -1px;
}
.system-tree>ol .expanded:not(:last-child):not(:first-child),
.system-tree li:not(:last-child) {
    background-repeat: no-repeat;
}
.system-tree .leaf:last-child {
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAKklEQVQoU2M8d+HSfyMDPUYGEgBJimHmMo7aBAmK0dCDJgl4ioClDGJoAGUZM32Z0U8tAAAAAElFTkSuQmCC);
    */background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFG2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNi4wLWMwMDYgNzkuZGFiYWNiYiwgMjAyMS8wNC8xNC0wMDozOTo0NCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnBob3Rvc2hvcD0iaHR0cDovL25zLmFkb2JlLmNvbS9waG90b3Nob3AvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0RXZ0PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VFdmVudCMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjQgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDIxLTA2LTA5VDE3OjA1OjA1LTA1OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAyMS0wNi0xMFQwOToyMzoxOS0wNTowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAyMS0wNi0xMFQwOToyMzoxOS0wNTowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9maWxlPSJzUkdCIElFQzYxOTY2LTIuMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpiMjYxYzdiNi0yMjcxLTRhMmQtOTA0Yy05YmVkNTM0MWM4MTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6YjI2MWM3YjYtMjI3MS00YTJkLTkwNGMtOWJlZDUzNDFjODE2IiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6YjI2MWM3YjYtMjI3MS00YTJkLTkwNGMtOWJlZDUzNDFjODE2Ij4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDpiMjYxYzdiNi0yMjcxLTRhMmQtOTA0Yy05YmVkNTM0MWM4MTYiIHN0RXZ0OndoZW49IjIwMjEtMDYtMDlUMTc6MDU6MDUtMDU6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChNYWNpbnRvc2gpIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIaXN0b3J5PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsO/HeMAAAAwSURBVCiR7Y2xDQAgEITQ/WczxoX46vuzVhoqAmsfCVFRGWnQEcC8OTX/9MIpNUABNStBL5EcbxkAAAAASUVORK5CYII=);
    background-position: 11px 0;
    background-repeat: no-repeat;
    margin-top: -1px;
}
.system-tree li.expanded.folder:not(.loading)>.title-wrap { /* this doesn't seem to be doing anything */
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAMCAYAAACji9dXAAAAFUlEQVQIW2M8d+HSf0YGBgYGRpJZAJ2uFKcp4HfpAAAAAElFTkSuQmCC);
  */  background-position: 31px 13px;
    background-repeat: no-repeat;
}
.system-tree .folder:last-child:not(:only-child),
.system-tree li .folder:last-child {
    background-position: 11px 0;
}

.system-tree li>.title-wrap {
    min-height: 25px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.system-tree .icon:before {
    background-position: 0 0;
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
}
.system-tree .icon-collapse,
.system-tree .icon-expand {
    font: normal normal normal 15px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: var(--dark-gray);
    border-radius: 3px;
}
.system-tree .icon.icon-collapse:before {
    /*content: "\f147";*/
    content: "\f107";
    margin-top: -2px;
}
.system-tree .icon.icon-expand:before {
    /*content: "\f196";*/
    content: "\f105";
    margin-top: -2px;
    margin-left: 2px;
}

.system-tree .toggle {
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    z-index: 2;
}
.system-tree .toggle:before {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
}
.system-tree [type=checkbox] {
    left: 18px;
    position: absolute;
    top: 3px;
    vertical-align: middle;
    width: 20px;
    z-index: 2;
}
.system-tree .title {
    cursor: default;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    overflow: hidden;
    padding-left: 42px;
    position: relative;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 1;
}
.system-tree .title:before {
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: top;
}
.system-tree .node-name {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
}

.system-tree .wholerow {
    height: 25px;
    left: 0;
    margin-top: -25px;
    position: absolute;
    width: 100%;
    z-index: 1;
    cursor: pointer;
}
.system-tree .wholerow:hover {
    background: var(--light-gray);
    opacity: .5;
    border-radius: 8px;
    padding: 0px 30px;
}
.system-tree .title-wrap:hover + .wholerow {
    background: var(--light-gray);
    opacity: .5;
    border-radius: 8px;
    padding: 0px 30px;
}
.system-tree .selected > .title-wrap:hover + .wholerow,
.system-tree .selected > .wholerow {
    background: var(--light-gray);
    opacity: .5;
    border-radius: 8px;
    padding: 0px 30px;
}

.system-tree .disabled,
.system-tree .disabled .node-name,
.system-tree .disabled .wholerow,
.system-tree .disabled .title-wrap,
.system-tree .disabled input {
    cursor: not-allowed;
    color: var(--blue-gray);
}

.system-tree .btn {
    background-color: var(--white);
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 3px;
    display: inline-block;
    height: 20px;
    transition: all .15s linear;
    width: 20px;
}
.system-tree .btn-group {
    display: none;
    height: 23px;
    line-height: 23px;
    position: absolute;
    right: 10px;
    z-index: 3;
}
.system-tree .btn-group button {
    display: inline-block;
    width: 22px;
    height: 22px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}
.system-tree .editable > .title-wrap:hover > .btn-group {
    display: inline-block;
}

.system-tree div.title-wrap.hover {
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
}