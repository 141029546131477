.status-indicator {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    margin: 0 0 5px 5px;
}
.status-indicator .progress-bar .percentage {
    font-weight: bold;
    vertical-align: middle;
    line-height: 1.9;
}
.console-wrapper button.sy {
    font-size: 14px;
    background: none;
    color: var(--dark-gray);
    box-shadow: none;
    font-weight: bold;
    padding: 0;
    line-height: .25;
    text-align: center;
    padding-right: 16px;
    margin-top: 2px;
    width: 97%;
    text-align: right;
    z-index: 40;
    position: absolute;
}

.console-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    display: flex;
    padding-left: 5px;
    box-sizing: border-box;
}
.console {
    height: 19px;
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    background: var(--white);
    box-sizing: border-box;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex: 1;
}
.console .timestamp {
    color: var(--label-gray);
}
.console.open {
    height: 200px;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: auto;
    justify-content: flex-start;
}

.status-indicator .progress-bar {
    flex: 1;
    height: 19px;
    background: var(--light-gray);
    border-radius: 10px;
    margin-right: 5px;
    text-align: center;
}
.status-indicator .inner {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
}
.status-indicator .inner .bar {
    display: flex;
    height: 100%;
    width: 0;
    background: var(--green);
    border-radius: 20px;
}

.status-indicator .inner .bar.error {
    background: var(--red);
}

.status-indicator .percentage {
    position: absolute;
    font-size: 11px;
    left: 10px;
    font-weight: bold;
    margin-top: 1.5px;
}
.status-indicator .bar.indeterminate {
    width: 50%;
    animation: progress-animation2 2s cubic-bezier(0.0, 0.0, 1.0, 1.0) infinite;
}