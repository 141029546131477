.share-column > button {
    border-radius: 10px;
}
.share-column > button,
.share-column > button > i,
.share-column > i {
    cursor: pointer;
}

#share-tool-dialog {
    padding: 0 5px;
    overflow: auto;
}

.share-prompt {
    margin: 1vh 0;
    margin-left: 12px;
}

.take-share-img, .share-img-box {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: var(--very-light-gray);
    background-repeat: no-repeat;
    background-size: cover;
}
.take-share-img:hover, .share-img-box:hover {
    background-color: #FCFCFC;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.125);
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.add-img-text {
    top: 62%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-size: 11px;
    text-align: center;
    color: var(--dark-gray);
    opacity: .5;
}
.add-img-text span {
    font-size: 9px;
}

.checked {
    color:var(--green);
}

.unchecked {
    color: var(--blue-gray);
}

#share-link-dialog button {
    padding: 8px 100px;
    cursor: pointer;
}

#share-link-dialog {
    margin: 2%;
    margin-top: -5%;
    text-align: center;
}

#share-dialog-title {
    font-size: 18px;
}

#share-dialog-text {
    border-style: none;
    border-color: transparent;
    overflow: auto;
    resize: none;
    font-size: 13px;
    height: 35%;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 2.5%;
    color: var(--label-gray);
    text-align: center;
}

@media only screen and (max-height: 900px) {
    #share-link-dialog {
        margin-top: -8%;
    }
}
@media only screen and (max-height: 800px) {
    #share-link-dialog {
        margin-top: -12%;
    }
}

#share-dialog-text input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.share-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}
.share-column {
    display: flex;
    flex: 1;
    height: 11vh;
    width: 10vw;
    position: relative;
    margin: 1.5%;
}
.share-column .placeholder-img {
    width: 35px;
    opacity: .3;
    margin:0 auto;
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
}
#share-photo-five {
    flex: 0.5;
    left: 50%;
    transform: translateX(-50%);
}

.share-contain {
    display: flex;
    flex-direction: column;
}
#share-sales-phone {
    width: 37%;
    margin-right: 3%;
}
#share-sales-company {
    width: 50%;
}
.banner-cursor {
    cursor: pointer;
}

.edit-project > span {
    margin-left: 2%;
}

.edit-title {
    margin-top: -15px;
    margin-bottom: -10px;
    float: left;
    display: flex;
    flex-direction: row;
}

#share-project-name {
    text-align: left;
    width: 41%;
    margin-left: 10px;
    margin-top: -2px;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.edit-button {
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
}

.edit-project-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 0%;
}
.edit-project-header > .extra-info {
    margin-top: -7px;
}
/*.edit-project-group > label {
    color: var(--label-gray);
    display: inline-block;
    width: 15%;
    text-align: left;
    margin-right: 20px;
}*/

.edit-project-group input {
    width: 93.5%;
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin-left: -1.5%;
}

.edit-project-group {
    margin-left: 2%;
    display: flex;
    align-items: center;
    padding: 1% 0 1% 0;
}

.edit-project {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 5px;
}

.check-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 4%;
    color: var(--label-gray);
}

.check-row span {
    margin: 0 1% 0px 3%;
    text-align: left;
}

.check-row i {
    font-size: 16px;
    color: var(--gray);
}

#share-checkboxes {
    margin-top: 10px;
}

button.sy.thumbnail-select {
    position: absolute;
    top: 30%;
    z-index: 200;
    width: 32px;
    height: 32px;
    text-align: center;
}

.banner-thumbnail-list {
    display: flex;
    flex-wrap: wrap;
}
#banner-thumbnail-dialog {
    height: 355px;
    background-color: var(--white);
    position: fixed;
    transform: translate(4.5%, 52px);
    border: 1px solid var(--light-gray);
    overflow: scroll;
    z-index: 5;
    border-radius: 8px;
}
#banner-thumbnail-dialog > img {
    cursor: pointer;
}
#banner-thumbnail-dialog > ul {
    list-style-type: none;
}
#banner-thumbnail-dialog .thumb {
    border-radius: 3px;
}

.banner-option {
    margin-left: 5px;
    cursor: pointer;
}

.banner-option > a > img {
    position: relative;
    left: -20%;
    top: -70%;
}
.qmark-info {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: -1px;
    text-align: center;
    background-color: var(--light-blue);
    border-radius: 50%;
    min-width: 20px;
    width: 20px;
    height: 20px;
    font-size: 15.5px;
    vertical-align: middle;
    line-height: 1.5;
    display: inline-block;
}

.extra-info::before {
    content: "?";
    font-weight: bold;
    color: var(--white);
}

.edit-title .qmark-info {
    margin-top: 18px;
}

.wait-photo-banner {
    position: absolute;
    top: 26%;
    left: 43%;
    color: var(--dark-gray);
    opacity: .3;
}

.wait-photo {
    position: absolute;
    top: 25%;
    left: 36%; /* this is not a good way to set this up as there's no defined width for the icon...will revisit */
    color: var(--dark-gray);
    opacity: .3;
}

.extra-info p {
    display: none;
    text-align: left;
    background-color: var(--white);
    padding: 10px;
    width: 310px;
    position: absolute;
    border-radius: 3px;
    border-style: double;
    border-width: medium;
    box-shadow: 1px 1px 1px var(--black-transp);
    right: -70px;
    color: var(--green);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    z-index: 300;
}

.extra-info p::after{
    width:100%;
    height:40px;
    content:'';
    position: absolute;
    top:40px;
    z-index: 300;
}

.tippy-box {
    font-family: "Poppins", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.tippy-box {
    max-width: 350px;
}

.share-btn-container button.sy {
    text-align: center;
    padding: 8px 8px;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 15px;
    margin-bottom: 20px;
}